.freetrial2-dialog{
    margin: 15px;
    margin-top: 0px;
}
.freetrial2-dialog .title {
    color: #363636;
    font-weight: 400;
    font-size: 28px;
}
.freetrial2-dialog .sub-title {
    color: #727272;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    margin-top: 24px;
}
.freetrial2-dialog .here-is{
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: 0.01em;
    color: #5E5D5D;
    margin-top: 24px;
    margin-bottom: 12px;
}
.freetrial2-dialog .item-wrapper {
    display: flex;
    align-items: center;
    margin: 12px 0px;
}
.freetrial2-dialog .item-wrapper .item-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: #727272;
    margin-left: 13px;
}

.freetrial2-dialog .continue-btn {
    position: relative;
    margin-top: 44px;
    margin-bottom: 34px;
}
.freetrial2-dialog .continue-btn .continue-loader {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.freetrial2-dialog .continue-btn svg{
    display: block;
    margin: auto;
    cursor: pointer;
}
.freetrial2-dialog-freetrialend-dialog-close {
    display: flex;
}
.freetrial2-dialog-freetrialend-dialog-close svg {
    margin-left: auto;
    cursor: pointer;
}