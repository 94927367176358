.mobile-caption-section {
  background-color: #ffffff;
  width: 100%;
  text-align: center;
  padding: 20px 0 10px 0;
}

.mobile-caption-section .text {
  font-size: 27px;
  font-weight: 600;
  color: #363636;
}

.mobile-caption-section .text span {
  color: #CF5FA6;
}

.mobile-caption-section .sub-text {
  font-size: 14.66px;
  font-weight: 400;
  color: #333333;
  margin: auto;
  margin-top: 10px;
  max-width: 300px;
}

.mobile-caption-section .trial .trial-input {
  margin-top: 22px;
  color: #828282;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  text-shadow: none;
  line-height: 40px;
  border:none;
  border-radius: 8px;
  padding-left: 5px;
  width: 309px;
  background-color: #ffffff;
  filter: drop-shadow(1.414213562373095px 1.4142135623730951px 10px #c3c3c3);
  padding-left: 15px;
}

.mobile-caption-section .trial .trial-btn {
  margin: auto;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 13.33px;
  width: 170px;
  height: 37.23px;
  font-weight: 600;
  border-radius: 7px;
  margin-top: 35px;
  cursor: pointer;
}

.mobile-caption-section .no-credit-text{
  color: #828282;
  font-weight: 300;
  font-size: 14.66px;
  margin-top: 10px;
}
.mobile-caption-section .rephase-img{
  margin: 0px 0px;
  width: 80%;
}
.mobile-caption-section .whether-you-text{
  width: 70%;
  margin: auto;
  font-size: 17.33px;
  color: #363636;
  font-weight: 300;
}

.mobile-caption-section .card-wrapper {
  padding-top: 55px;
  padding-bottom: 108px;
  background-color: #ffffff;
  display: flex;
  margin: auto;
  max-width: 1100px;
}

@media (max-width: 1100px) {
  .mobile-caption-section .card-wrapper {
    display: block;
    padding-bottom: 0px;
  }
}

.mobile-caption-section .card-wrapper .col {
  margin: auto;
}

@media (max-width: 1100px) {
  .mobile-caption-section .card-wrapper .col {
    padding-bottom: 30px;
  }
}

.mobile-caption-section .card-wrapper .card {
  margin: auto;
  width: 360px;
  padding: 40px 20px 20px 20px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.mobile-caption-section .card-wrapper .card2 {
  background-color: #fbfbfb;
}

.mobile-caption-section .card-wrapper .card .title {
  color: #434343;
  text-decoration: none;
  font-size: 18.6667px;
  font-weight: 600;
  text-align: center;
}

.mobile-caption-section .card-wrapper .card .price {
  color: #2a2a2a;
  text-align: center;
  font-weight: 400;
  font-size: 64px;
  line-height: 46px;
  margin-top: 20px;
}

.mobile-caption-section .card-wrapper .card .month {
  text-align: center;
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  margin-top: 25px;
  margin-bottom: 30px;
}

.mobile-caption-section .card-wrapper .card .lines-wrapper {
  display: flex;
  height: 37px;
}

.mobile-caption-section .card-wrapper .card img {
  margin-left: 8px;
  margin-right: 15px;
  width: 21px;
  height: 21px;
}

.mobile-caption-section .card-wrapper .card .lines {
  color: #333333;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  height: 37px;
}

.mobile-caption-section .card-wrapper .btn-get-me {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 220px;
  height: 38px;
  line-height: 38px;
  font-size: 13.33px;
  color: #ffffff;
  font-weight: 400;
  border-radius: 11px;
  background-color: #d967be;
  text-align: center;
}

.mobile-caption-section .card-wrapper .btn-get-me.current {
  background-color: #16201873;
  cursor: unset;
}

@media (max-width: 500px) {
  .mobile-caption-section .card-wrapper .card {
    width: 320px;
  }
}

.mobileLandingStyle-wrapper .devider {
  border:none;
  width: 100px;
  height: 2px;
  border-radius: 3px;
  margin: auto;
  background-color: #d967be;
}


.mobile-faq .main-title{
  color: #333333;
  font-weight: 600;
  font-size: 26.66px;
  text-align: center;
  padding: 40px 0px 30px 0px;
}
.mobile-faq .question .q-text {
  color: #333333;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    padding: 15px;
}
.mobile-faq .question .a-text {
  color: #333333;
  font-weight: 400;
  font-size: 14.66px;
  text-align: center;
  padding: 10px 25px 20px 25px;
}
.mobileLandingStyle-wrapper .trial-btn {
  margin: auto;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 13.33px;
  width: 165px;
  height: 37.23px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 5px;
  margin-bottom: 45px;
  cursor: pointer;
}

.mobileLandingStyle-wrapper .mobile-header{
  height: 75px;
  background: #eeeeee;
  filter: drop-shadow(-0.0px -0.0px 11px #939393);
  position: relative;
}
.mobileLandingStyle-wrapper .mobile-header .mobile-logo{
  margin: auto;
  height: 75px;
  display: block;
}
.mobileLandingStyle-wrapper .mobile-header .menu-btn{
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0px, -50%);
}
.mobileLandingStyle-wrapper .mobile-header .mobile-menu{
  position: fixed;
  top: 75px;
  right: 0px;
  left: 0px;
  background-color: #ffffff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s, opacity 2.5s linear;
}
.mobileLandingStyle-wrapper .mobile-header .mobile-menu.active {
  visibility: visible;
    opacity: 1;
}

.mobileLandingStyle-wrapper .mobile-header .mobile-menu .menu-item{
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #363636;
  background-color: #eeeeee;
  border: 1px solid #ffffff;
  font-weight: 400;
  font-size: 18.66px;
}