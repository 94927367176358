.contact-us-account {
  text-align: center;
  padding-top: 20vh;
  padding-bottom: 25vh;
}
.contact-us-account .title-1 {
  color: #333333;
  font-size: 13.33px;
}
.contact-us-account .title-2 {
  color: #333333;
  margin-top: 2px;
  font-size: 34.33px;
}
.contact-us-account .title-3 {
  color: #333333;
  margin-top: 5px;
  font-size: 13.33px;
}
.contact-us-account .btn {
  padding: 8px;
  color: #ffffff;
  font-size: 13.33px;
  background-color: #39b48a;
  border-radius: 11px;
  display: inline-block;
  width: 125px;
  margin-top: 20px;
  cursor: pointer;
}
