body .sign-up {
  /* background-color: #f3f3f3; */
  background-image: url('../../assestes/images/bg-img.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.sign-up input {
  color: #434343;
  text-align: left;
  text-decoration: none;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 300;
  text-shadow: none;
  line-height: 46px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  padding: 1px 6px 1px 11px;
  width: 406px;
  background-color: #ffffff;
}
.sign-up .MuiFormHelperText-root.Mui-error {
  max-width: 358px;
}
@media (max-width: 500px) {
  .sign-up input {
    width: 100%;
    font-size: 16px;
    width: 358px;
  }
  .sign-up .MuiFormHelperText-root.Mui-error {
    max-width: 100%;
  }
}

.sign-up .name-wrapper {
  display: flex;
}
.sign-up .fname,
.sign-up .lname {
  display: inline-block;
  vertical-align: top;
}
.sign-up .fname input,
.sign-up .lname input {
  width: 197px;
  vertical-align: top;
}
.sign-up .name-wrapper .MuiFormHelperText-root.Mui-error {
  max-width: 164px;
}
.sign-up .lname {
  margin-left: 12px;
}

.sign-up .react-tel-input .flag-dropdown.invalid-number,
.sign-up .react-tel-input .flag-dropdown {
  border: none;
  background: none;
}
.sign-up .react-tel-input {
  font-size: 13.333px;
  line-height: 46px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  width: 406px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 12px;
}
.sign-up .react-tel-input .form-control {
  width: 100%;
  border: none !important;
  background: none !important;
}
.sign-up .react-tel-input .country-list .search-box {
  width: unset;
  padding: 3px 8px 5px !important;
}
.sign-up .tnc{
  font-size: 14px;
  font-weight: 500;
  color: black;
  text-align: center;
  margin-bottom: 20px;
}
.sign-up .tnc a {
  font-size: 14px;
  color: #d967be;
  font-weight: 600;
  text-decoration: none;
}

@media (max-width: 500px) {
  .sign-up .fname input,
  .sign-up .lname input {
    width: 100%;
  }
  .sign-up .name-wrapper .MuiFormHelperText-root.Mui-error {
    width: 100%;
  }
}

.sign-up .card-wrapper {
  margin: auto;
  margin-top: 50px;
}
@media (max-width: 778px) {
  .sign-up .card-wrapper {
    margin: 15px;
    margin-top: 40px;
  }
}
@media (max-width: 450px) {
  .sign-up .card-wrapper {
    margin-top: 30px;
  }
}
.sign-up .card {
  margin: auto;
  background-color: #ffffff;
  padding: 48px;
  display: table;
  border-radius: 32px;
  box-shadow: 4px 4px 26px rgba(0, 0, 0, 0.15);
  margin-bottom: 70px;
}
.sign-up .card.low-pad {
  padding: 40px 50px;
}
@media (max-width: 778px) {
  .sign-up .card {
    padding: 45px;
  }
}
@media (max-width: 500px) {
  .sign-up .card {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .sign-up .card {
    padding: 15px;
  }
}
.sign-up .main-title {
  color: #363636;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  margin-top: 90px;
}
.sign-up .mt-30 {
  margin-top: 30px;
}

.sign-up .card .title {
  color: #363636;
  font-weight: 400;
  font-size: 32px;
  text-align: left;
  margin: auto;
  margin-bottom: 20px;
}
.sign-up .card .title1 {
  color: #363636;
  font-weight: 600;
  font-size: 18.66px;
  text-align: center;
  margin: auto;
  margin-bottom: 35px;
}
.sign-up .card .ui-input {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 12px;
}
/* .sign-up input.otp {
  border: none;
  border-bottom: 2px solid #d967be;
} */
.sign-up .card .btn-login {
  margin: auto;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 16px;
  width: 160px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 35px;
  cursor: pointer;
  margin-bottom: 20px;
}
.sign-up .card .btn-login:disabled {
  background-color: gray;
  transform: scale(1);
}
.sign-up .link {
  width: 50%;
  display: inline-block;
  text-align: center;
  color: #b2b2b2;
  font-weight: 400;
  font-size: 13.33px;
  line-height: 19px;
  cursor: pointer;
}
