.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@import "node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "node_modules/bootstrap/scss/bootstrap-reboot.scss";


@import './typography';

.wrapper {
  background-image: url('../../assestes/images/bg-img.jpg');
  background-position: center 0px ;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    width: 100%;
  }
}

.top-section {
  padding-top: 83px;
  margin-bottom: 20px;


  h1 {
    max-width: 494px;
    text-align: center;
    span {
      color:#CF5FA6;
    }
    font-weight: 700;
  }

  h5 {
    max-width: 430px;
    text-align: center;
  }


  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 28px;
  }

  // .top-section__form
  &__form {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 24px;


    @media (min-width: 520px) {
      flex-direction: row;
      align-items: flex-start;
      column-gap: 24px;
      row-gap: unset;
    }

    input {
      min-width: 290px;
    }

    svg {
      margin-right: 5px;
    }

    span {
      font-size: 14px;
      line-height: 22px;
      color: rgba(54, 54, 54, 0.7)
    }
  }

  // .top-section__button
  &__button {
    margin-bottom: 10px;
  }
}

.features {
  padding-top: 25px;
  margin-bottom: 140px;

  h2 {
    text-align: center;
    margin-bottom: 32px;
  }
}

.feature-item {
  background: #FFFFFF;
  box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.04);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  margin-bottom: 30px;

  @media screen and (min-wigth: 992px) {
    margin-bottom: 0;
  }

  &__img {
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 7.09903px 7.09903px 13.3107px rgba(0, 0, 0, 0.05);
    margin-bottom: 12px;
    padding: 22px;
    img {
      width: 48px;
    }
  }

  h4 {
    margin-bottom: 4px;
  }
}

.writers-block {
  margin-bottom: 140px;

  .section{

    &__text {
      padding-left: 50px;
      padding-right: 50px;

      @media (min-width: 992px) {
        padding-left: 114px;
        padding-right: 80px;
      }
    }

    &__wrapper {
      padding-top: 72px;
      padding-bottom: 67px;
    }
  }

  h2 {
    margin-bottom: 20px;
  }
  
  p {
    margin-bottom: 36px;
    @media (min-width: 992px) {
      max-width: 358px;
    }
  }
}

.section {

		// .section__wrapper

		&__wrapper {
      background: #FFFFFF;
      box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.04);
      border-radius: 32px;
		}

    &__img {
      padding-left: 80px;
    }

    &__text {
      padding-right: 80px;
    }
}

.btn {
  background: #CF5FA6;
  border-radius: 12px;
  padding: 12px 36px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
  border: none;
  display: inline-block;

  &:hover {
    color: #FFFFFF;
  }
}

.teams {

  h2 {
    margin-bottom: 20px;
  }

  p {
    max-width: 365px;
  }

  .btn {
    margin-top: 36px;
  }
}

.plans {
  padding-top: 52px;

  h2 {
    text-align: center;
    margin-bottom: 63px;
  }
  // .plans__items

  &__items {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    flex-direction: column;
    row-gap: 20px;

    @media (min-width: 768px) {
      flex-direction: row;
      row-gap: unset;
    }

    @media (min-width: 992px) {
      column-gap: 98px;
    }
  }

  // .plans__item

  &__item {
    text-align: center;

    h4 {
      margin-bottom: 24px;
    }

    .card__list {
      text-align: left;
    }
  }

  .btn {
    max-width: 292px;
    display: block;
    margin: 0 auto;
  }

  // .plans__price
  &__price {
    text-align: center;

    h3 {
      font-weight: 600;
      font-size: 48px;
      line-height: 67px;
      letter-spacing: 0.01em;
      color: #CF5FA6;
      margin: 0;
    }
    
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #969696;
      margin-bottom: 24px;
    }
  }
}

.card {
    background: #FFFFFF;
    box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.05);
    border-radius: 32px;
    padding: 32px;

		// .card__list

		&__list {
      list-style: none;
      padding: 0;
      padding-left: 32px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      margin-bottom: 26px;

      li {
        position: relative;
        font-size: 16px;
        line-height: 27px;
        color: #727272;
        
        &::after {
          content: ' ';
          background-image: url('../../assestes/images/check-circle.svg');
          width: 20px;
          height: 20px;
          position: absolute;
          left: -32px;

        }
      }
		}
}

.faq {
  margin-top: 77px;

  h2 {
    text-align: center;
    margin-bottom: 37px;
  }
  // .faq__item

  &__item {
    margin-bottom: 20px;
  }

  h2.faq__subtitle {
    text-align: left;
    margin-bottom: 20px;
  }
}



.contact {
  margin-top: 64px;
  margin-bottom: 152px;

  h2 {
    margin-bottom: 65px;
    text-align: center;
  }

  &__content {
    h2 {
      text-align: left;
      margin-bottom: 20px;
    }

    h2, p {
      
      @media (min-width: 992px) {
        max-width: 398px;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;

    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }
}

.contact-form {

  background: #FFFFFF;
  box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.04);
  border-radius: 32px;
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;


  &__inputs {
    display: flex;
    width: 100%;
    column-gap: 24px;
    margin-bottom: 24px;

    label {
      font-size: 20px;
      line-height: 28px;
      color: #363636;
      margin-bottom: 4px;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  
  textarea {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    width: 100%;
    min-height: 199px;

    margin-bottom: 24px;
  }

  .btn {
    max-width: 160px;
  }
}

.for-free {
  text-align: center;
  margin-bottom: 120px;
  margin-top: 120px;

  &__content {
    padding: 72px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    margin-bottom: 17px;
  }

  h5 {
    max-width: 510px;
    margin-bottom: 19px;
  }

  .btn {
    max-width: 230px;
    margin-bottom: 19px;
  }

  span {
    font-size: 14px;
    line-height: 22px;
    color: rgba(54, 54, 54, 0.7);

    svg {
      margin-right: 8px;
    }
  }
}

