body .story-article-wrapper {
  background-color: #f3f3f3;
  min-height: 100vh;
}
.story-article .title {
  margin: auto;
  text-align: center;
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  margin-top: 40px;
}
@media (max-width: 710px) {
  .story-article .title {
    display: block;
    margin-top: 30px;
  }
}

.story-article .d-flex {
  display: flex;
}
.story-article .card-wrapper {
  margin: auto;
  display: flex;
  margin-top: 25px;
}
@media (max-width: 710px) {
  .story-article .card-wrapper {
    display: block;
    margin-top: 20px;
  }
}

.story-article .card {
  width: 363px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 20px 40px 40px 40px;
  margin: 15px;
}

@media (max-width: 800px) {
  .story-article .card {
    width: 323px;
  }
}
@media (max-width: 370px) {
  .story-article .card {
    width: 303px;
  }
}

@media (max-width: 800px) {
  .story-article .card {
    padding: 20px 30px 30px 30px;
  }
}
@media (max-width: 450px) {
  .story-article .card {
    padding: 20px;
  }
}

.story-article .card .title {
  font-weight: 600;
  margin: auto;
  text-align: center;
  color: #333333;
  font-size: 20px;
}
.story-article img {
  margin-top: 50px;
  margin: auto;
  display: block;
}

.story-article img.story {
  width: 173px;
  height: 163.5px;
  margin-top: 50px;
  padding-left: 25px;
}
.story-article img.article {
  width: 137px;
  height: 163.5px;
  margin-top: 50px;
}

.story-article .btn-start {
  margin: auto;
  text-align: center;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 16px;
  width: 145px;
  height: 42px;
  line-height: 43px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 35px;
  cursor: pointer;
  margin-bottom: 10px;
}
