.header-wrapper {
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
}
.header-wrapper.bgWhite {
  background-color: #ffffff;
  z-index: 100;
}
.header-wrapper .header {
  margin: auto;
  padding-top: 58px;
  display: flex;
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 16px;
  color: #5e5e5e;
}
.header-logo {
  width: 79px;
  height: 79px;
  cursor: pointer;
  margin-top: -32px;
}
.header .header-item {
  line-height: 31px;
  margin: 0px 25px;
  width: 92px;
  height: 52px;
  cursor: pointer;
  text-align: center;
}
.header .header-item.btn-affiliates{
  width: 165px;
}

.header .header-item .active-item {
  margin: auto;
  margin-top: 2px;
  width: 100px;
  height: 3px;
  background-color: #d967be;
}

.header .btn-header-login {
  margin-left: 20px;
  background-color: #d967be;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  width: 126px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  border-radius: 11px;
  cursor: pointer;
}
.header .btn-header-logout {
  margin-right: 30px;
  background-color: #d967be;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  width: 126px;
  height: 31px;
  line-height: 31px;
  text-align: center;
  border-radius: 11px;
  cursor: pointer;
}
.menu {
  margin-left: auto;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 6px;
  margin: 20px 20px 15px 15px;
  float: right;
}


.desktop-only {
  display: flex;
}
.mobile-only {
  display: none;
  margin-left: auto;
}
#top-menu .MuiPopover-paper {
  border-radius: 10px;
  background: #f8f8f8;
}
#top-menu ul {
  padding: 0px;
}
#top-menu .MuiListItem-root.Mui-focusVisible {
  background: #f8f8f8;
}
#top-menu .MuiListItem-root.Mui-focusVisible:hover {
  background: rgba(0, 0, 0, 0.04);
}

#top-menu li {
  padding: 0px;
  color: #333333;
  text-align: center;
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 45px;
  border-bottom: 1px solid #d9d9d9;
}

@media (max-width: 820px) {
  .desktop-only {
    display: none !important;
  }
  .mobile-only {
    display: flex;
  }
}

.mobile-header {
  height: 75px;
  background: #eeeeee;
  filter: drop-shadow(-0.0px -0.0px 11px #939393);
  position: relative;
  margin-bottom: 40px;
}

.mobile-header .mobile-logo {
  margin: auto;
  height: 75px;
  display: block;
}

.mobile-header .menu-btn {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0px, -50%);
}

.mobile-header .mobile-menu {
  position: fixed;
  top: 75px;
  right: 0px;
  left: 0px;
  background-color: #ffffff;
  visibility: hidden;
  opacity: 0;
  transition: visibility 2s, opacity 2.5s linear;
}

.mobile-header .mobile-menu.active {
  visibility: visible;
  opacity: 1;
}

.mobile-header .mobile-menu .menu-item {
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #363636;
  background-color: #eeeeee;
  border: 1px solid #ffffff;
  font-weight: 400;
  font-size: 18.66px;
}