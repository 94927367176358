.billing-info {
  width: 100%;
  display: flex;
}
@media (max-width: 1100px) {
  .billing-info {
    display: block;
  }
}
.billing-info .card-details,
.billing-info .payment-history {
  flex: 1;
}
.billing-info .card-details .title,
.billing-info .payment-history .title {
  color: #d967be;
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  padding: 15px;
}
.billing-info .invoice-item {
  border: 1px solid #dddddd;
  border-radius: 2px;
  max-width: 360px;
  margin: 20px auto;
  display: flex;
  padding: 15px;
}
.billing-info .invoice-item .date {
  color: #434343;
  text-align: left;
  font-weight: 300;
  font-size: 13.33px;
}
.billing-info .invoice-item .status {
  color: #333333;
  text-align: right;
  font-weight: 600;
  font-size: 14.66px;
  flex-grow: 1;
}
.billing-info .card-number {
  border: 1px solid #dddddd;
  border-radius: 2px;
  max-width: 360px;
  margin: 20px auto;
  color: #434343;
  text-align: left;
  font-weight: 300;
  font-size: 13.33px;
  padding: 15px;
}
.billing-info .cvv-wrapper {
  display: flex;
  max-width: 360px;
  margin: 20px auto;
}
.billing-info .cvv-wrapper .cvv {
  flex: 1;
  border: 1px solid #dddddd;
  border-radius: 2px;
  color: #434343;
  text-align: left;
  font-weight: 300;
  font-size: 13.33px;
  padding: 15px;
}
.billing-info .cvv-wrapper .space {
  width: 20px;
}
.billing-info .btn-update {
  border-radius: 8px;
  color: white;
  background-color: #d967be;
  width: 160px;
  font-weight: 600;
  font-size: 13.33px;
  line-height: 43px;
  text-align: center;
  margin: auto;
  margin-top: 40px;
  cursor: pointer;
}
