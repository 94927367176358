.create-new-plus {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-block;
  width: 41px;
  height: 41px;
}
.create-new-plus img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 35px;
  cursor: pointer;
}
.create-new-plus svg {
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.create-new-plus:hover .plus-icon {
  display: none;
}
.create-new-plus .plus-icon-hover {
  display: none;
}
.create-new-plus:hover .plus-icon-hover {
  display: block;
}
.create-new-plus.active .plus-icon-hover {
  display: block;
}
.create-new-plus ellipse {
  transform: rotate(0deg);
  fill: #d967be;
}
.create-new-plus:hover ellipse,
.create-new-plus.active ellipse {
  transform: rotate(0deg);
  fill: #a84e92;
}
.create-new-plus-menu-popup {
  margin-top: 30px !important;
}
.create-new-plus-menu .menu-items {
  width: 130px;
  text-align: center;
  background: #fbfbfb;
  padding: 12px 32px;
  border: 1px solid #cfcfcf;
  border-radius: 7px;
}
.create-new-plus-menu .menu-items .item {
  font-size: 14.66px;
  font-weight: 400;
  line-height: 33px;
  cursor: pointer;
}
.create-new-plus-menu .menu-item-list:hover .item {
  font-weight: 300;
}
.create-new-plus-menu .menu-items .item:hover {
  font-weight: 600;
}

@media (max-width: 1270px) {
  .create-new-plus {
    bottom: 25px;
    right: 20px;
  }
}
@media (max-width: 1250px) {
  .create-new-plus {
    right: 10px;
  }
}
@media (max-width: 1100px) {
  .create-new-plus {
    display: none;
  }
}
