* {
  font-family: "Open Sans", Arial;
  box-sizing: border-box;
  outline: none;
}

.f-adobe {
  font-family: "Adobe Myungjo Std", Arial;
}

input:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

.mt-20 {
  margin-top: 20px;
}
.ml-a {
  margin: unset;
  margin-left: auto;
}
.m-a{
  margin: auto;
}

.hover-zoom {
  transition: all 0.4s ease-in-out;
}

.hover-zoom:hover {
  transform: scale(1.05);
}

.hover-zoom1 {
  transition: all 0.4s ease-in-out;
}

.hover-zoom1:hover {
  transform: scale(1.1);
}

.hover-btext {
  transition: all 0.2s ease-in-out;
}
.hover-btext:hover {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

.txt-main {
  color: #333333;
  text-decoration: none;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 21.33px;
}

.search-box {
  padding: 15px !important;
  padding-bottom: 20px !important;
  width: 100%;
}
.search-box .MuiInputBase-root {
  border: 1px solid #dddddd;
  border-radius: 4px;
  background: #ffffff;
  font-size: 15px;
  padding: 1px 10px;
}
@media (max-width: 500px) {
  .search-box .MuiInputBase-root {
      font-size: 16px;
    }
}

::-webkit-scrollbar {
  width: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: transparent;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d967be;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d967be;
}

/* .gray-scroll::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 2px;
}

/* Handle on hover */
/* .gray-scroll::-webkit-scrollbar-thumb:hover {
  background: gray;
} */
.back-white {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: -1;
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }
  .mobile-only {
    display: inherit;
  }
}

.fade-effect {
  opacity: 0;
}

.hover-pink:hover {
  color: #d967be !important;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d967be;
  color: #d967be;
  -webkit-animation: dot-flashing 1s infinite linear alternate;
  animation: dot-flashing 1s infinite linear alternate;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d967be;
  color: #d967be;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #d967be;
  color: #d967be;
  -webkit-animation: dot-flashing 1s infinite alternate;
  animation: dot-flashing 1s infinite alternate;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes dot-flashing {
  0% {
    background-color: #d967be;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}

@keyframes dot-flashing {
  0% {
    background-color: #d967be;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
@media (max-width: 500px) {
  .react-tel-input .form-control {
    font-size: 16px;
  }
}
.team-bg{
  background-color: #1eaaf1 !important;
}

.d-flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}

.pink-button{
  height: 32px;
  width: 122px;
  display: block;
  text-align: center;
  font-size: 13.33px;
  color: #fff;
  background-color: #d967be;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

ul {
  margin: 0;
}

ul.dashed {
  list-style-type: none;
}

ul.dashed>li {
  text-indent: -5px;
}

ul.dashed>li:before {
  content: "-  ";
  text-indent: -5px;
}

.night-mode,
.night-mode .header-wrapper.bgWhite,
body .night-mode .Dashboard-wrapper,
.night-mode .writing-pad-wrapper textarea.title,
.night-mode .writing-pad-wrapper textarea.note {
  background-color: #424242;
  color: white;
}

.night-mode .customise-length-mobile,
.night-mode .writing-pad-wrapper .length-customizer-wrapper {
  background-color: #333333;
  color: white;

}

.night-mode .writing-pad-wrapper .states {
  color: white;
}

.night-mode .writing-pad-wrapper .logo-space img,
.night-mode .writing-pad-wrapper .btn-header .btn-action.hover-zoom img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.night-mode .writing-pad-wrapper .logo-space img,
.night-mode .writing-pad-wrapper .btn-header .btn-action.hover-zoom img,
.night-mode .writing-pad-wrapper .mobile-bottom .help-icon,
.night-mode .create-new-mobile img,
.night-mode .writing-pad-wrapper .team-note-view-full-article img,
.night-mode .writing-pad-wrapper .brief img:last-child,
.night-mode .writing-pad-wrapper .btn-delete img,
.night-mode .action-section .edit-section img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.night-mode .writing-pad-wrapper .btn-header .btn-action.hover-zoom:hover img {
  -webkit-filter: unset;
  filter: unset;
}

.night-mode .writing-pad-wrapper .btn-undo,
.night-mode .writing-pad-wrapper .btn-copy,
.night-mode .writing-pad-wrapper .btn-desk,
.night-mode .writing-pad-wrapper .btn-delete,
.night-mode .writing-pad-wrapper .brief,
.night-mode .team-mode .text,
.night-mode .profile-section .profile-name .name,
.night-mode .profile-section .profile-name .greeting,
.night-mode .team-note-full-view .team-note-item .team-note-item-details .text,
.night-mode .team-note-full-view .team-note-item .team-note-item-title,
.night-mode .team-note-full-view .team-note-item .team-note-item-details .assignee,
.night-mode .profile-section .profile-name .name,
.night-mode .action-section .edit-section .edit,
.night-mode .list-wrapper .items-wrapper .item .title,
.night-mode .list-wrapper .items-wrapper .item .note,
.night-mode .btn-load-more,
.night-mode .create-new-section .text,
.night-mode .empty-view .team-empty-workspaces,
.night-mode .tab-wrapper .tab-item {
  color: white !important;
}
.night-mode .team-note-full-view .team-note-content .team-note-item{
  opacity: 1 !important;
}

.night-mode .writing-pad-wrapper textarea.brief,
.night-mode .list-wrapper .items-wrapper .item,
.night-mode .list-wrapper .search-box div {
  background-color: #909090;
  color: white;
}

.night-mode .header-wrapper.bgWhite svg,
.night-mode .action-section .select-all .action svg path,
.night-mode .item .manage-section svg{
  fill: white !important;
}

.night-mode .writing-pad-wrapper textarea.brief::placeholder {
  color: #FFFFFF !important;
}

.night-mode .writing-pad-wrapper ::-moz-selection {
  /* Code for Firefox */
  background: #394f67;
}

.night-mode .writing-pad-wrapper ::selection {
  background: #394f67;
}

.night-mode .writing-pad-wrapper .note-text-selection-tooltip {
  background: #315a84;
  color: white;
}

.night-mode .writing-pad-wrapper .bottom-arrow::before {
  border-top: 7px solid #315a84;
}

.night-mode .writing-pad-wrapper .note-tooltip-item:hover {
  background: #315a84;
}

.night-mode .sidemenu-drawerPaper .MuiDrawer-paper {
  background-color: #333333 !important;
}

.night-mode .create-new-section,
.night-mode .list-wrapper,
.night-mode .tab-wrapper .tab-item,
.night-mode .tab-wrapper .tab-grid {
  background-color: #6c6c6c !important;
}
.night-mode .tab-item.active,
.night-mode .item.active .item-content{
  background-color: #333333 !important;
}

.night-mode .writing-pad-wrapper .mobile-bottom{
  background-color: #333333;
}

.night-mode .writing-pad-wrapper .mobile-bottom .more-icon svg {
  fill: white;
}

.night-mode .drawer-wrapper {
  background-color: #333333;
}

.night-mode .sidemenu-drawerPaper .MuiDrawer-paper {
  border-right: 1px solid #333333;
}

.night-mode .team-note-full-view{
  overflow-x: auto
}
.night-mode .writing-pad {
  overflow-x:auto;
  padding-bottom: 20px;
  
}

.mw-133{
  max-width: 133.33px;
}