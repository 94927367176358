.create-workspace-note{
    min-height: 110vh;
}
.create-workspace-note .title{
    color:#333333;
    font-size: 26.66px;
    font-weight: 700;
    text-align: center;
    padding: 10px 15px;
}
.create-workspace-note .sub-title {
    color: #333333;
    font-size: 18.66px;
    font-weight: 400;
    text-align: center;
    padding: 5px 15px;
}
.create-workspace-note .sub-title span{
    color: #D967BE;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}
.create-workspace-note .title-input{
    width: 100%;
    background-color: #ffffff;
    height: 40px;
    border-radius: 7px;
    filter: drop-shadow(0.5566924038402614px 3.9610722749662814px 11px #C5C5C5);
    max-width: 850px;
    margin: 20px auto 35px auto;
    display: flex;
    overflow: hidden;
}
.create-workspace-note .title-input input{
    flex:1;
    border:none;
    padding-left: 10px;
    color: #000000;
    font-weight: 400;
    font-size: 14px;
}
.create-workspace-note .title-input button {
    margin: 4px;
    height: 32px;
    width: 122px;
    font-size: 13.33px;
    color:#fff;
    background-color: #d967be;
    border: none;
    border-radius: 7px;
    cursor: pointer;
}
.create-workspace-note .title-input button:disabled {
    background-color: rgba(217, 103, 190, 0.7);
}
.create-workspace-note .note-item {
    height: 68px;
    margin: 20px auto;
    display: flex;
    border-radius: 7px;
    background-color: #f9f9f9;
    width: 100%;
    max-width: 1068px;
}
.create-workspace-note .note-item .title-section{
    flex: 1;
    display: flex;
}
.create-workspace-note .note-item .title-section .item-title{
    margin: auto 20px;
    display: inline-block;
}
.create-workspace-note .note-item .title-section .edit-icon {
    margin: auto 10px auto 0px;
    cursor: pointer;
}
 .create-workspace-note .note-item .title-section .edit-icon svg:hover {
     color: #6a6a6a !important;
 }
.create-workspace-note .note-item .assign-section {
    margin: auto;
    display: flex;
    color:#333333;
    font-size: 14px;
}
.create-workspace-note .note-item .assign-section .assing-drop-down {
    border: 1px solid #DDDDDD;
    border-radius: 7px;
    width: 120px;
    height: 22px;
    display: inline-flex;
    margin: 0px 15px 0px 5px;
    /* overflow: hidden; */
    cursor: pointer;
    position: relative;
}
.assign-popup {
    display: block;
    min-width: 212px;
    padding: 5px;
    background: #ffffff;
}
.assign-popup .search{
    padding: 5px;
}
.assign-popup .search input{
    width: 100%;
    border: none;
    border-bottom: 1px solid lightgray;
}
.assign-popup .user{
    color: #434343;
    text-decoration: none;
    font-size: 13.33px;
    padding: 3px;
    cursor: pointer;
}
.assign-popup .user.active {
    border: 1px solid lightgray;
    border-radius: 4px;
}

.create-workspace-note .note-item .assign-section .assing-drop-down .user-text {
    color:#434343;
    font-size: 13.33px;
    padding-left: 10px;
    flex:1;
    line-height: 20px;
}
.create-workspace-note .note-item .assign-section .assing-drop-down .arrow {
    width: 17.5px;
    height: 20px;
    background-color: #d967be;
    position: relative;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}
.create-workspace-note .note-item .assign-section .assing-drop-down .arrow svg{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(180deg);
}