body .login {
  /* background-color: #f3f3f3; */
  background-image: url('../../assestes/images/bg-img.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
body .login img {
  width: 100%;
}
.login input {
  color: #434343;
  text-align: left;
  text-decoration: none;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 300;
  text-shadow: none;
  line-height: 46px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  padding: 1px 6px 1px 11px;
  width: 358px;
  background-color: #ffffff;
}
@media (max-width: 500px) {
  .login input {
    width: 100%;
    font-size: 16px;
  }
}

.login .card-wrapper {
  margin: auto;
  margin-top: 65px;
  margin-bottom: 75px;    
}
@media (max-width: 778px) {
  .login .card-wrapper {
    margin: 15px;
    margin-top: 40px;
  }
}
@media (max-width: 450px) {
  .login .card-wrapper {
    margin-top: 30px;
  }
}

.login .card {
  margin: auto;
  background-color: #ffffff;
  padding: 48px;
  display: table;
  border-radius: 32px;
  box-shadow: 4px 4px 26px rgba(0, 0, 0, 0.15);
}
@media (max-width: 778px) {
  .login .card {
    padding: 45px;
  }
}
@media (max-width: 500px) {
  .login .card {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .login .card {
    padding: 15px;
  }
}

.login .card .title {
  color: #363636;
  font-weight: 400;
  font-size: 32px;
  text-align: left;
  margin: auto;
  margin-bottom: 20px;
}
.login .card input {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 12px;
}
.login .card .btn-login {
  margin: auto;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 16px;
  width: 160px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 35px;
  cursor: pointer;
  margin-bottom: 24px;
}
.login .link {
  width: 100%;
  display: inline-block;
  text-align: center;
  color: #3f3f3f;
  font-weight: 400;
  font-size: 13.33px;
  line-height: 19px;
  cursor: pointer;
}
.login .link:last-child {
  margin-top:16px ;
}
.login .mt-20 {
  margin-top: 20px;
}
