.fixed-corner-loading {
  width: 90px;
  position: fixed;
  bottom: 20px;
  right: 90px;
}
.fixed-corner-loading .text {
  color: #6b6b6b;
  font-size: 14px;
  font-weight: 300;
  margin-top: 15px;
  display: block;
}

@media (max-width: 1270px) {
  .fixed-corner-loading {
    bottom: 25px;
    right: 20px;
  }
}
@media (max-width: 1250px) {
  .fixed-corner-loading {
    width: 77px;
    right: 10px;
  }
  .fixed-corner-loading .text {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  .fixed-corner-loading {
    display: none;
  }
}
