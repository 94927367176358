body .reset-password-new {
    /* background-color: #f3f3f3; */
    background-image: url('../../assestes/images/bg-img.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.reset-password-new input {
    color: #434343;
    text-align: left;
    text-decoration: none;
    font-size: 13.333px;
    font-style: normal;
    font-weight: 300;
    text-shadow: none;
    line-height: 46px;
    border: 1px solid #dddddd;
    border-radius: 2px;
    padding: 1px 6px 1px 11px;
    width: 358px;
    background-color: #ffffff;
}
.reset-password-new .MuiFormHelperText-root.Mui-error {
    max-width: 358px;
}

@media (max-width: 500px) {
    .reset-password-new input {
        width: 100%;
    }

    .reset-password-new .MuiFormHelperText-root.Mui-error {
        max-width: 100%;
    }
}

.reset-password-new .password-wrapper {
    display: flex;
}

.reset-password-new .password,
.reset-password-new .confirmpassword {
    display: inline-block;
    vertical-align: top;
}

.reset-password-new .password input,
.reset-password-new .confirmpassword input {
    width: 164px;
    vertical-align: top;
}

.reset-password-new .password-wrapper .MuiFormHelperText-root.Mui-error {
    max-width: 164px;
}

.reset-password-new .confirmpassword {
    margin-left: 29px;
}

.reset-password-new .react-tel-input .flag-dropdown.invalid-number,
.reset-password-new .react-tel-input .flag-dropdown {
    border: none;
    background: none;
}

.reset-password-new .react-tel-input {
    font-size: 13.333px;
    line-height: 46px;
    border: 1px solid #dddddd;
    border-radius: 2px;
    width: 406px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
}
.reset-password-new .input-title{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #363636;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 8px;
}

.reset-password-new .react-tel-input .form-control {
    width: 100%;
    border: none !important;
    background: none !important;
}

.reset-password-new .react-tel-input .country-list .search-box {
    width: unset;
    padding: 3px 8px 5px !important;
}

@media (max-width: 500px) {

    .reset-password-new .password input,
    .reset-password-new .confirmpassword input {
        width: 100%;
    }

    .reset-password-new .password-wrapper .MuiFormHelperText-root.Mui-error {
        width: 100%;
    }
}

.reset-password-new .card-wrapper {
    margin: auto;
    margin-top: 50px;
}

@media (max-width: 778px) {
    .reset-password-new .card-wrapper {
        margin: 15px;
        margin-top: 40px;
    }
}

@media (max-width: 450px) {
    .reset-password-new .card-wrapper {
        margin-top: 30px;
    }
}

.reset-password-new .card {
    margin: auto;
    background-color: #ffffff;
    padding: 48px;
    display: table;
    border-radius: 32px;
    box-shadow: 4px 4px 26px rgba(0, 0, 0, 0.15);
    margin-bottom: 70px;
    margin-top: 90px;
}

.reset-password-new .card.low-pad {
    padding: 40px 50px;
}

@media (max-width: 778px) {
    .reset-password-new .card {
        padding: 45px;
    }
}

@media (max-width: 500px) {
    .reset-password-new .card {
        width: 100%;
    }
}

@media (max-width: 450px) {
    .reset-password-new .card {
        padding: 15px;
    }
}

.reset-password-new .main-title {
    color: #363636;
    text-decoration: none;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    margin-top: 90px;
}

.reset-password-new .progress-bar {
    display: flex;
    margin-bottom: 40px;
    margin-top: 10px;
}
.reset-password-new .progress-bar .item {
    flex: 1;
    text-align: center;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    color: #AEAEAE;
    mix-blend-mode: normal;
    padding: 6px;
    border-bottom: 4px solid #DDDDDD;
}
.reset-password-new .progress-bar .item.active-item {
    border-bottom: 4px solid #CF5FA6;
}
.reset-password-new .progress-bar .item.active-text {
    color: #363636;
}

.reset-password-new .mt-30 {
    margin-top: 30px;
}

.reset-password-new .card .title {
    color: #282828;
    font-weight: 600;
    font-size: 37.333px;
    text-align: center;
    margin: auto;
    margin-bottom: 35px;
}

.reset-password-new .card .title1 {
    color: #363636;
    font-weight: 400;
    font-size: 32px;
    text-align: left;
    margin: auto;
    margin-bottom: 20px;
}

.reset-password-new input.otp {
    border: none;
    border-bottom: 2px solid #d967be;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding-left: 12px;
}

.reset-password-new .card .btn-login {
    margin: auto;
    display: block;
    background-color: #d967be;
    color: #ffffff;
    border: none;
    font-size: 16px;
    width: 160px;
    height: 43px;
    line-height: 43px;
    font-weight: 600;
    border-radius: 11px;
    margin-top: 35px;
    cursor: pointer;
    margin-bottom: 35px;
}

.reset-password-new .card .btn-login:disabled {
    background-color: gray;
    transform: scale(1);
}

.reset-password-new .link {
    width: 50%;
    display: inline-block;
    text-align: center;
    color: #b2b2b2;
    font-weight: 400;
    font-size: 13.33px;
    line-height: 19px;
    cursor: pointer;
}