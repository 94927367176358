.manage-workspace{
    min-height: 100vh;
}
.manage-workspace .ws-name{
    color: #333333;
    font-weight: 700;
    font-size: 26.66px;
    text-align: center;
    margin: 0px 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    padding-left: 150px;
}
.manage-workspace .actions{
    max-width: 900px;
    margin: auto;
    padding: 15px;
    padding-top: 0px;
    padding-right: 35px;
    display: flex;
}
.manage-workspace .actions button{
    font-size: 14px;
    color:#fff;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
}
.manage-workspace .actions .delete{
    cursor: pointer;
}
.manage-workspace .actions button:first-child{
    margin-left: auto;
    margin-right: 15px;
}
.manage-workspace .team-list{
    max-width: 900px;
    margin: auto;
    padding: 15px;
}
.manage-workspace .team-list .team-item-header{
    display: flex;
    padding: 15px;
}
.manage-workspace .team-list .team-item-header div {
    flex: 1;
    text-transform: capitalize;
    line-height: 24px;
    font-weight: 700;
}
.manage-workspace .team-list .team-item-header .role,
.manage-workspace .team-list .team-item-header .status,
.manage-workspace .team-list .team-item-header .action {
    text-align: center;
}
.manage-workspace .team-list .team-item{
    width: 30%;
    margin: 1.5%;
    padding: 25px 15px;
    background: #f9f9f9;
    border-radius: 10px;
    color: #333333;
    font-size: 14px;
    margin-bottom: 15px;
    text-transform: capitalize;
    display: inline-block;
    position: relative;
}
.manage-workspace .team-list .team-item .delete-icon{
    display: none;
    position: absolute;
    right: 10px;
    top:10px;
    cursor: pointer;
}
.manage-workspace .team-list .team-item:hover .delete-icon {
    display: block;
}
.manage-workspace .lettered-avatar{
    font-weight: 700;
}
.manage-workspace .add-member{
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
}
.manage-workspace .add-member:hover {
    box-shadow :1.414213562373095px 1.4142135623730951px 10px #c3c3c3;
}
.manage-workspace .add-member .lettered-avatar-wrapper{
    background: lightgray !important;
}
.manage-workspace .team-list .team-item div {
    margin: auto;
}
.manage-workspace .team-list .team-item .name{
    margin-top: 15px;
    margin-bottom: 7px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #5e5e5e;
}
.manage-workspace .team-list .team-item .role{
    margin-bottom: 16px;
}
.manage-workspace .team-list .team-item .role,
.manage-workspace .team-list .team-item .status{
    height: 19px;
}
.manage-workspace .team-list .team-item .role,
.manage-workspace .team-list .team-item .status,
.manage-workspace .team-list .team-item .action
{
    text-align: center;
}

.manage-workspace .team-list .team-item .action button {
    /* font-size: 14px; */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 3px 10px;
    cursor: pointer;
    background: #ef4136;
}

.manage-workspace .role-key{
    line-height: 29px;
    margin-right: 5px;
    height: 30px;
    margin-top: 3px;
    font-weight: 300;
}
.manage-workspace .role-key{

}
.manage-workspace .role-key .toggle{
    margin-left: 3px;
}
@media (max-width: 740px) {
    .manage-workspace .team-list .team-item {
        width: 47%;
        margin: 1.5%;
    }
    .manage-workspace .ws-name {
        text-align: left;
        padding: 0px;
    }
}
@media (max-width: 540px) {
    .manage-workspace .team-list .team-item {
        width: 100%;
        margin: 1.5% 0%;
    }
}