.customise-length-mobile {
  width: 240px;
  background-color: #f4f4f4;
}
.customise-length-mobile .title {
  text-align: center;
  color: #333333;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 15px;
}
.customise-length-mobile h4 {
  font-size: 16px;
  font-weight: 400;
  color: #db91c9;
}
.customise-length-mobile .control {
  display: flex;
  text-align: center;
  width: 300px;
}
.customise-length-mobile .control-item {
  height: 62px;
  display: block;
  min-width: 80px;
  background-color: #f4f4f4;
}
.customise-length-mobile .control-item .text {
  font-size: 14px;
  color: #cccccc;
  font-weight: 300;
  display: inline-block;
  line-height: 36px;
  margin-right: 10px;
  width: 100%;
}
.customise-length-mobile .control-item .text.active {
  color: #d967be;
  font-weight: 600;
}
.customise-length-mobile .round {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 13px;
  background: white;
}
.customise-length-mobile .round .text {
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}
.customise-length-mobile .round.active {
  background: #d967be;
}
.customise-length-mobile .line {
  position: relative;
  height: 72px;
}
.customise-length-mobile .line .line-content {
  position: absolute;
  height: 5px;
  top: 10px;
  left: -27px;
  right: -28px;
  background: white;
}
.customise-length-mobile .line img {
  position: absolute;
  left: -31px;
  right: -1px;
  height: 5px;
  bottom: 15.5px;
  width: 92px;
}

.generate-assisted .button {
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 14px;
  width: 220px;
  height: 42px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
  padding: 10px ;
  z-index: 1700;
  margin: auto;
  display: block;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}
.generate-assisted .button.disabled {
  background-color: gray;
    transform: scale(1);
}
.generate-assisted .sub-text {
  font-size: 13px;
  text-align: center;
  padding: 10px;
  width: 200px;
  margin: auto;
}