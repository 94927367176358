.delete-dialog {
  padding: 0px 0px 0px;
}
.delete-dialog .text {
  color: #333333;
  font-size: 17.3333px;
}
.delete-dialog .text b {
  font-weight: 600;
}
.delete-dialog .delete-title-1{
  color: #b03628;
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 30px;
}
.delete-dialog .delete-title-2 {
  color: #000;
  width: 320px;
  text-align: center;
  margin: auto;
  margin-bottom: 30px;
}
.delete-dialog .delete-note-list{
  color: #000;
  margin-left: -15px;
}
.delete-dialog .delete-note-list li{
  margin-bottom: 10px;
}
.delete-dialog .confirm-input-div {
  margin: 20px 25px 5px 25px;
}
.delete-dialog .confirm-input{
  margin-top: 15px;
  border: none;
  border-bottom: 2px solid #d967be;
  width: 100%;
  font-size: 16px;
  padding: 5px 3px;
}

.d-flex {
  display: flex;
}
.btn-wrapper {
  display: flex;
  margin: auto;
  margin-top: 25px;
}
.d-btn {
  width: 106px;
  text-align: center;
  margin: 15px;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 13.33px;
  height: 36px;
  line-height: 36px;
  font-weight: 600;
  border-radius: 11px;
  cursor: pointer;
  padding: 0px 35px;
}
.d-btn.btn-no {
  background-color: #787878;
}
