.footer {
  background: #FFFFFF;
		// .footer__bottom

  &__bottom {
    padding: 28px 0;

    p {
      color: #363636;
    }

    &-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        column-gap: 36px;

        a {
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          color: #363636;
          opacity: 0.9;
        }
      }
    }
  }

  &__nav {
    display: flex;
    column-gap: 36px;
  }
  
  &__top {
    padding-top: 59px;
    padding-bottom: 33px;
    border-bottom: 1.5px solid #DFE4EB;
  }

  .logo {
    img {
      width: 72px;
    }
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}


.social {
  display: flex;
  column-gap: 28px;
  list-style: none;
  padding: 0;
  margin-bottom: 0;

		// .social__item

		&__item {
		}
}