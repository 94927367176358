#register-overlay-modal {
  width: 100%;
}
.register-overlay-modal {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1800;
  top: 0;
  left: 0;
  overflow-x: hidden;
  backdrop-filter: blur(6px);
  background-color: rgb(193 193 193 / 30%);
}
.welcome-board {
  max-width: 1250px;
}
.welcome-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  height: 400px;
  border-radius: 4px;
}
.welcome-box .close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.welcome-box .left-box {
  background-color: #ffffff;
  width: 324px;
  border-radius: 8px 0 0 8px;
}
.welcome-box .left-box .page-dots {
  text-align: center;
  padding-top: 40px;
}
.welcome-box .left-box .page-dots .dot {
  height: 6px;
  width: 6px;
  background-color: #cccccc;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}
.welcome-box .left-box .left-box-title {
  color: rgb(35, 35, 35);
  text-decoration: none;
  font-weight: 700;
  font-size: 25.33px;
  text-align: center;
  padding-top: 28px;
}
.welcome-box .left-box .left-box-toggle-button {
  justify-content: center;
  display: flex;
  margin-top: 24px;
}
.welcome-box .left-box .left-box-desc {
  color: rgb(35, 35, 35);
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  padding: 0 45px;
  text-align: center;
  padding-top: 40px;
}
.welcome-box .left-box .left-box-desc-v1 {
  color: rgb(35, 35, 35);
  text-decoration: none;
  font-weight: 400;
  padding: 0 35px;
  font-size: 16px;
  text-align: center;
  padding-top: 40px;
}
.welcome-box .left-box .left-box-btn {
  justify-content: center;
  margin: auto;
  display: grid;
  grid-auto-flow: column;
  padding-top: 70px;
}
.welcome-box .left-box .left-box-desc .badge {
  background-color: #d967be;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  font-size: 14.66px;
  padding: 0px 12px;
  border-radius: 6px;
}
.welcome-box .left-box .left-box-btn .btn-next {
  padding: 4px 18px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  background: #4a5c6e;
  border-radius: 8px;
  cursor: pointer;
}
.welcome-box .left-box .left-box-btn .btn-skip {
  padding: 4px 16px;
  color: rgb(74, 92, 110);
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: underline;
}
.welcome-box .left-box .prev-btn-arrow {
  height: 56px;
  width: 56px;
  max-width: 56px;
  position: absolute;
  background: #cacaca;
  left: 0;
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  margin: auto;
  cursor: pointer;
}
.welcome-box .left-box .prev-btn-arrow:hover {
  background: #ececec;
}
.welcome-box .left-box .prev-btn-arrow img {
  padding: 10px;
  filter: invert(100%) sepia(8%) saturate(0%) hue-rotate(123deg)
    brightness(117%) contrast(100%);
}
.welcome-box .right-box {
  width: 495px;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome-box .right-box .right-box-text {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 700;
  font-size: 40px;
}
.welcome-box .right-box .right-box-text-small {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 400;
  font-size: 26.66px;
  margin-top: 10px;
}
.welcome-box .right-box .right-box-bottom-img img {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.welcome-box .right-box .next-btn-arrow {
  height: 56px;
  width: 56px;
  max-width: 56px;
  position: absolute;
  background: white;
  right: -58px;
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  margin: auto;
  cursor: pointer;
}
.welcome-box .right-box .next-btn-arrow:hover {
  background: #ececec;
}
.welcome-box .right-box .next-btn-arrow img {
  padding: 10px;
}
.welcome-box .right-box .right-box-img img {
  height: 210px;
  margin: auto;
}
.slider-box-1 {
  background-color: #ffbc6a !important;
}
.slider-box-2 {
  background-color: #87c2ff !important;
}
.slider-box-3 {
  background-color: #4a5c6e !important;
}
.slider-box-4 {
  background-color: #e789d0 !important;
}
.flex-direction-column {
  flex-direction: column !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.text-align-initial {
  text-align: initial !important;
}

@media only screen and (max-width: 950px) {
  .welcome-box {
    display: block;
    width: 80%;
    height: auto;
  }
  .welcome-box .left-box {
    width: 100%;
    border-radius: 8px 8px 0 0;
  }
  .welcome-box .right-box {
    width: 100%;
    min-height: 200px;
    border-radius: 0 0 8px 8px;
  }
  .welcome-box .right-box .right-box-img img {
    height: 185px;
    margin: auto;
  }
  .welcome-box .right-box .right-box-text {
    font-size: 26px;
  }
  .welcome-box .right-box .right-box-bottom-img img {
    bottom: 8px;
    right: 8px;
  }
  .welcome-box .left-box .left-box-btn {
    padding: 40px;
  }
  .welcome-box .left-box .prev-btn-arrow {
    height: 45px;
    width: 45px;
  }
  .welcome-box .right-box .next-btn-arrow {
    height: 45px;
    width: 45px;
    background: #cacaca;
    right: -45px;
  }
  .welcome-box .right-box .next-btn-arrow:hover {
    background: #ececec;
  }
  .welcome-box .right-box .next-btn-arrow img {
    filter: invert(100%) sepia(8%) saturate(0%) hue-rotate(123deg)
      brightness(117%) contrast(100%);
  }
}
