.email-note-dialog {
  padding: 0px 0px 0px;
}

.email-note-dialog {
  color: #000;
  width: 320px;
  text-align: center;
  margin: auto;
}
.email-note-dialog .okay-btn {
  width: 106px;
  text-align: center;
  margin: 25px auto 15px;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 13.33px;
  height: 36px;
  line-height: 36px;
  font-weight: 600;
  border-radius: 11px;
  cursor: pointer;
  padding: 0px 35px;
}