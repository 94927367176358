body .otp-verifying {
  background-color: #f3f3f3;
}

.otp-verifying input {
  color: #434343;
  text-align: left;
  text-decoration: none;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 300;
  text-shadow: none;
  line-height: 46px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  padding: 1px 6px 1px 11px;
  width: 358px;
  background-color: #ffffff;
}
.otp-verifying .MuiFormHelperText-root.Mui-error {
  max-width: 358px;
}

@media (max-width: 500px) {
  .otp-verifying input {
    width: 100%;
  }

  .otp-verifying .MuiFormHelperText-root.Mui-error {
    max-width: 100%;
  }
}

.otp-verifying .password-wrapper {
  display: flex;
}

.otp-verifying .password,
.otp-verifying .confirmpassword {
  display: inline-block;
  vertical-align: top;
}

.otp-verifying .password input,
.otp-verifying .confirmpassword input {
  width: 164px;
  vertical-align: top;
}

.otp-verifying .password-wrapper .MuiFormHelperText-root.Mui-error {
  max-width: 164px;
}

.otp-verifying .confirmpassword {
  margin-left: 29px;
}

.otp-verifying .react-tel-input .flag-dropdown.invalid-number,
.otp-verifying .react-tel-input .flag-dropdown {
  border: none;
  background: none;
}

.otp-verifying .react-tel-input {
  font-size: 13.333px;
  line-height: 46px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  width: 358px;
  background-color: #ffffff;
  border: none;
  border-bottom: 2px solid #d967be;
}
.otp-verifying .react-tel-input.disabled {
  border-bottom: 2px solid gray;
}

.otp-verifying .react-tel-input .form-control {
  width: 100%;
  border: none !important;
  background: none !important;
}

.otp-verifying .react-tel-input .country-list .search-box {
  width: unset;
  padding: 3px 8px 5px !important;
}

@media (max-width: 500px) {
  .otp-verifying .password input,
  .otp-verifying .confirmpassword input {
    width: 100%;
  }

  .otp-verifying .password-wrapper .MuiFormHelperText-root.Mui-error {
    width: 100%;
  }
}

.otp-verifying .card-wrapper {
  margin: auto;
  margin-top: 50px;
}

@media (max-width: 778px) {
  .otp-verifying .card-wrapper {
    margin: 15px;
    margin-top: 40px;
  }
}

@media (max-width: 450px) {
  .otp-verifying .card-wrapper {
    margin-top: 30px;
  }
  .otp-verifying .react-tel-input {
    width: 100%;
  }
}

.otp-verifying .card {
  margin: auto;
  background-color: #ffffff;
  padding: 30px 66px 20px 66px;
  display: table;
  border-radius: 8px;
  margin-bottom: 70px;
}

.otp-verifying .card.low-pad {
  padding: 40px 50px;
}

@media (max-width: 778px) {
  .otp-verifying .card,.otp-verifying .card.low-pad {
    padding: 45px;
  }
}

@media (max-width: 500px) {
  .otp-verifying .card,.otp-verifying .card.low-pad {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .otp-verifying .card,.otp-verifying .card.low-pad {
    padding: 15px;
  }
}

.otp-verifying .main-title {
  color: #363636;
  text-decoration: none;
  font-weight: 600;
  font-size: 21px;
  text-align: center;
  max-width: 600px;
  margin: 50px auto 30px auto;
}

.otp-verifying .mt-30 {
  margin-top: 30px;
}

.otp-verifying .card .title {
  color: #282828;
  font-weight: 600;
  font-size: 37.333px;
  text-align: center;
  margin: auto;
  margin-bottom: 35px;
}

.otp-verifying .card .title1 {
  color: #363636;
  font-weight: 600;
  font-size: 18.66px;
  text-align: center;
  margin: auto;
  margin-bottom: 35px;
}

.otp-verifying input.otp {
  border: none;
  border-bottom: 2px solid #d967be;
}
.otp-verifying input.otp.disabled {
  border-bottom: 2px solid gray;
}
.otp-verifying .card .btn-login {
  margin: auto;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 16px;
  width: 160px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 35px;
  cursor: pointer;
  margin-bottom: 35px;
}

.otp-verifying .card .btn-login:disabled {
  background-color: gray;
  transform: scale(1);
}

.otp-verifying .link {
  width: 50%;
  display: inline-block;
  text-align: center;
  color: #b2b2b2;
  font-weight: 400;
  font-size: 13.33px;
  line-height: 19px;
  cursor: pointer;
}
