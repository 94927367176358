.chat-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
}
.chat-wrapper .back-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.chat-wrapper .back-img img {
    width: 100%;
    height: 100%;
}
.chat-wrapper .panel-1 {
    /* position: absolute;
    top:0;
    bottom: 0; */
    width: 260px;
    height: 100%;
    background: #FFFFFF;
    overflow: auto;
    z-index: 1;
    border-right: 1px solid #DFE4EB;
}
.chat-wrapper .panel-1 .logo img {
    width: 40px;
    margin: auto;
    display: block;
    margin: 15px auto;
}

.chat-wrapper .panel-1 .chat-title {
    margin: 15px;
    cursor: pointer;
    border: 1px solid white;
    color: #515151;
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    border-radius: 5px;
    position: relative;
}
.chat-wrapper .panel-1 .chat-title.active {
    background-color: #F9F8F8;
}

.chat-wrapper .panel-1 .chat-title .text div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 50px 0px 10px;
}
.chat-wrapper .panel-1 .chat-title svg{
    position: absolute;
    right: 27px;
    top:9px;
    display: none;
}
.chat-wrapper .panel-1 .chat-title svg.edit-icon{
    top: 11px;
}
.chat-wrapper .panel-1 .chat-title .delete-icon {
    position: absolute;
    right: 7px;
    top: 9px;
}
.chat-wrapper .panel-1 .chat-title.active svg,
.chat-wrapper .panel-1 .chat-title.active .delete-icon,
.chat-wrapper .panel-1 .chat-title.edit svg,
.chat-wrapper .panel-1 .chat-title.edit .delete-icon {
    display: unset;
}
.chat-wrapper .panel-1 .chat-title:hover svg,
.chat-wrapper .panel-1 .chat-title:hover .delete-icon {
    display: unset;
}
.chat-wrapper .panel-1 .new-chat-btn-wrapper{
    position: sticky;
    bottom: 0px;
    padding-bottom: 20px;
    padding-top: 10px;
    background: #FFFFFF;
}
.chat-wrapper .panel-1 .new-chat-btn {
    margin: 0px 15px;
    cursor: pointer;
    background-color: #d967be;
    color: white;
    font-weight: 700;
    font-size: 14px;
    line-height: 38px;
    text-align: center;
    border-radius: 5px;
}
.chat-wrapper .panel-2{
    position: relative;
    flex: 1;
    z-index: 1;
    overflow: hidden;
}
.chat-wrapper .panel-2 .chrome-extensions {
    transform: translate(-358px, 0px);
}
.chat-wrapper .panel-2 .header {
    text-align: right;
    display: flex;
}
.chat-wrapper .panel-2 .header svg{
    margin: 15px;
    margin-left: auto;
}

.chat-wrapper .header .user-mail {
    flex: 1;
    color: #333333;
    font-weight: 600;
    display: flex;
    cursor: pointer;
    line-height: 65px;
    padding-left: 15px;
}

.chat-wrapper .header .user-mail svg {
    margin: auto 5px;
}
.chat-wrapper .panel-2 .title {
    font-weight: 300;
    font-size: 20px;
    text-align: center;
}
.chat-wrapper .panel-2 .chat-box-area{
    width: 100%;
    box-sizing: border-box;
    max-width: 1048px;
    margin: 15px auto;
    padding: 15px;
    height: CALC(100% - 145px);
    position: relative;
}
.chat-wrapper .panel-2 .chat-box-area.with-msg-chat-list {
    background: #FFFFFF;
    filter: drop-shadow(2px 2px 24px rgba(0, 0, 0, 0.04));
    border-radius: 32px 32px 0px 0px;
}
.chat-wrapper .panel-2 .chat-box-area.with-msg-chat-list .white-patch-for-with-msg-chat-list {
    position: absolute;
    bottom: -32px;
    left: 0;
    right: 0;
    height: 32px;
    background: #ffffff;
}
@media (max-width: 768px) {
    .chat-wrapper .panel-2 .chat-box-area.with-msg-chat-list .white-patch-for-with-msg-chat-list {
        bottom: -15px;
    }
}
.chat-wrapper .panel-2 .chat-box-area .chat-list {
    position: absolute;
    top: 0;
    bottom: 80px;
    left: 0;
    right: 0;
    padding: 15px;
    overflow: auto;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list.with-msg-chat-list {
    background: #FFFFFF;
    border-radius: 32px 32px 0px 0px;
}

.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper .prompt-item{
    background: #FFFFFF;
    width: 270px;
    padding: 15px;
    text-align: center;
    margin: 20px;
    cursor: pointer;
    border-radius: 16px;
    filter: drop-shadow(5px 5px 30px rgba(0, 0, 0, 0.05));
    position: relative;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper .prompt-item .going-viral {
    position: absolute;
    background: #A2DB46;
    filter: drop-shadow(8px 8px 15px rgba(0, 0, 0, 0.05));
    width: 114px;
    border-radius: 16px;
    font-size: 11px;
    line-height: 27px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #ffffff;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -14px);
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper .prompt-item .prompt-icon{
    margin-top: 10px;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper .prompt-item .text-1{
    color: #363636;
    max-width: 232px;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin: 15px auto;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper .prompt-item .text-2 {
    color: #3A3A3A;
    font-weight: 400;
    font-size: 11px;
    line-height: 140%;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    width: 163px;
    height: 32px;
    line-height: 32px;
    margin: auto;
    background: #FFFFFF;
    box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin-bottom: 12px;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper .prompt-item .input{
    position: relative;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper .prompt-item .input .prompt-input {
    width: 220px;
    height: 32px;
    left: 1070px;
    top: 260px;

    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 16px;
    padding-left: 10px;
    padding-right: 31px;

}
.chat-wrapper .panel-2 .chat-box-area .chat-list .example-prompts-wrapper .prompt-item .input svg {
    position: absolute;
    top:10px;
    right: 21px;
}
.chat-wrapper .panel-2 .chat-box-area .chat-input-wrapper {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    height: 45px;
    padding: 0px 30px;
}
.chat-wrapper .panel-2 .chat-box-area .chat-input-wrapper .chat-input {
    width: 100%;
    display: flex;
    padding: 7px;
    border-radius: 5px;
    background: #FFFFFF;
    border-radius: 20px;
    filter: drop-shadow(8px 8px 15px rgba(0, 0, 0, 0.1));
    max-width: 816px;
    margin: auto;
}
.chat-wrapper .panel-2 .chat-box-area .chat-input-wrapper .chat-input,
.chat-wrapper .panel-2 .chat-box-area .chat-input-wrapper .chat-input textarea{
    flex: 1;
    border: none;
    margin: auto;
    padding: 12px 15px;
}
.chat-wrapper .panel-2 .chat-box-area .chat-input-wrapper .chat-input .btn-chat {
    border-radius: 5px;
    text-align: center;
    font-size: 17.33px;
    color:white;
    cursor: pointer;
    padding-top: 5px;
    margin-right: 10px;
    margin-left: 10px;
}

.chat-wrapper .panel-2 .chat-box-area .chat-list .chat-item {
    display: flex;
    margin: 15px auto;
    max-width: 816px;
    
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .chat-item .msg {
    width: 330px;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.5px;
    background: #FFEDF7;
    border-radius: 5px;
    color: #414141;
    padding: 10px 12px;
    white-space: break-spaces;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .chat-item.bot .msg {
    margin-left: auto;
    background: #F9F8F8;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .chat-item.bot .regenerate-wrapper {
    display: flex;
    margin-left: auto;
    margin-right: 35px;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .chat-item.bot .regenerate-wrapper img{
    cursor: pointer;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .chat-item .icon {
    width: 39px;
    line-height: 39px;
    height: 39px;
    text-align: center;
    border-radius: 100px;
    font-weight: 600;
    font-size: 9px;
    /* font-weight: 600; */
    margin: 0px 8px;
    background: #FFFFFF;
    filter: drop-shadow(2px 2px 14px rgba(0, 0, 0, 0.1));
    color: #CF5FA6;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .chat-item.bot .icon img {
    width: 100%;
}
.chat-wrapper .panel-2 .chat-box-area .chat-list .chat-item.bot {
    margin-left: auto;
}


.chat-wrapper .panel-1 .mobile-menu{
    position: absolute;
    right: 15px;
    top: 15px;
}
.chat-wrapper .panel-1 .mobile-menu .header svg{
    color: white !important;
}

@media (max-width: 768px) {
    .chat-wrapper.chat-list .panel-1 {
        width: 100%;
    }
    .chat-wrapper.chat-list .panel-2 {
        display: none;
    }
    .chat-wrapper.chat-view .panel-2 {
        width: 100%;
    }
    .chat-wrapper.chat-view .panel-1 {
        display: none;
    }
    .chat-wrapper .back-to-list{
        position: absolute;
        top: 15px;
        left: 15px;
        cursor: pointer;
    }
}

.chat-wrapper textarea {
    resize: none
}
