.login-overlay-modal {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  overflow-x: hidden;
}
.login-overlay-modal .back-img{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.login-overlay-modal .back-img img {
  width: 100%;
  height: 100%;
}
.login-overlay-modal .header-section {
  max-width: 1250px;
  margin: auto;
  padding-top: 106px;
  position: relative;
}
.login-overlay-modal .header-section .overlay-img {
  width: 51px;
  height: 51px;
  position: absolute;
  top: 20px;
  left: 45px;
}
.login-overlay-modal .header-section .closebtn {
  position: absolute;
  top: 0;
  right: 45px;
  font-size: 45px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: #000000;
}
.login-overlay-modal .header-section .header-title {
  color: #363636;
  text-decoration: none;
  font-weight: 400;
  font-size: 32px;
  text-align: center;
}
.login-overlay-modal .header-section .header-desc {
  color: #363636;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  margin-top: 17px;
  text-align: center;
}
.login-overlay-modal .box-section {
  max-width: 1250px;
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 28px;
}
.login-overlay-modal .box-section.m-a {
  margin: auto;
}
.login-overlay-modal .box-section .box {
  flex: 50%;
  max-width: 260px;
  margin: 35px;
  width: 318px;
  background: #ffffff;
  text-align: center;
  display: grid;
  align-items: center;
  cursor: pointer;
  position: relative;
  filter: drop-shadow(2px 2px 24px rgba(0, 0, 0, 0.1));
  border-radius: 32px;
  background: #FFFFFF;
}
.login-overlay-modal .box-section .box .new-label{
  position: absolute;
  top:-17px;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #d967be;
  width: 155px;
  height: 28px;
  color:white;
  border-radius: 8px;
  font-weight: 600;
  font-size: 11px;
  line-height: 140%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  text-align: center; */
}
.login-overlay-modal .box-section .box:first-child {
margin-left: auto;
}
.login-overlay-modal .box-section .box:last-child {
margin-right: auto;
}
.login-overlay-modal .box-section .selected-box {
  border: 2px solid #d967be;
}
.login-overlay-modal .box-section .box .box-title {
  text-decoration: none;
  letter-spacing: 0.01em;
  color: #363636;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.login-overlay-modal .box-section .box .box-desc {
  color: #727272;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 22px;
}
.login-overlay-modal .btn-section {
  max-width: 1250px;
  margin: auto;
}
.login-overlay-modal .btn-section .trial-btn {
  margin: auto;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 16px;
  width: 175px;
  height: 40px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 35px;
  cursor: pointer;
  margin-bottom: 70px;
}
.login-overlay-modal .btn-section .disabled-trial-btn {
  background-color: gray;
}
@media (max-width: 1024px) {
  .login-overlay-modal .box-section {
    flex-direction: column;
  }
  .login-overlay-modal .header-section .header-title {
    padding: 0 20px;
  }
  .login-overlay-modal .header-section .header-desc {
    padding: 0 20px;
  }
  .login-overlay-modal .box-section .box {
    margin: 30px auto;
  }
}
.mobile-login-overlay-modal{
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1800;
  top: 0;
  left: 0;
  background-color: #f3f3f3;
  overflow-x: hidden;
}
.mobile-login-overlay-modal .header-section {
  max-width: 1250px;
  margin: auto;
  padding-top: 106px;
  position: relative;
}

.mobile-login-overlay-modal .header-title {
  margin-top: 40px;
  color: #000000;
  text-decoration: none;
  font-weight: 700;
  font-size: 34.66px;
  text-align: center;
  padding-top: 9vh;
}

.mobile-login-overlay-modal .header-desc {
  color: #000000;
  text-decoration: none;
  font-weight: 400;
  font-size: 24px;
  margin-top: 17px;
  text-align: center;
}
.mobile-login-overlay-modal .box-section {
  max-width: 1250px;
  display: block;
  margin: auto;
  margin-top: 28px;
}

.mobile-login-overlay-modal .box-section .box {
  margin: 30px auto;
  height: 90px;
  width: 318px;
  position: relative;
  background: #ffffff;
  border-radius: 12px;
  border: 2px solid #ffffff;
  text-align: center;
  display: grid;
  align-items: center;
  cursor: pointer;
}
.mobile-login-overlay-modal .box-section .box img{
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  
}
.login-overlay-modal .box-section .box .box-img {
  margin-top: 20px;
  filter: drop-shadow(7.09903px 7.09903px 13.3107px rgba(0, 0, 0, 0.1));
  width: 120px;
}

.mobile-login-overlay-modal .box-section .selected-box {
  border: 1px solid #d967be;
}

.mobile-login-overlay-modal .box-section .box .box-title {
  color: #363636;
  text-decoration: none;
  font-weight: 400;
  font-size: 20px;
  padding-top: 18px;
  padding-right: 15px;
}

.mobile-login-overlay-modal .box-section .box .box-desc {
  color: #333333;
  text-decoration: none;
  font-weight: 300;
  font-size: 16px;
  padding-bottom: 20px;
  padding-right: 8px;
}
.mobile-login-overlay-modal .btn-section {
  max-width: 1250px;
  margin: auto;
}

.mobile-login-overlay-modal .btn-section .trial-btn {
  margin: auto;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 16px;
  width: 175px;
  height: 40px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 35px;
  cursor: pointer;
  margin-bottom: 70px;
}