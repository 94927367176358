.freetrial-dialog{
    margin: 15px;
    margin-top: 0px;
}
.freetrial-dialog .title {
    color: #363636;
    font-weight: 400;
    font-size: 28px;
}
.freetrial-dialog .sub-title {
    color: #727272;
    font-weight: 400;
    font-size: 16px;
    margin-top: 24px;
}
.freetrial-dialog .plan-tabs {
    margin: auto;
    background: #DFE4EB;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 6px;
    gap: 6px;
    width: 322px;
    height: 44px;
    background: #F3F3F3;
    border: 1px solid #DFE4EB;
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 24px;
}

.freetrial-dialog .plan-tabs .plan {
    cursor: pointer;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    margin: auto;
    gap: 4px;
    width: 152px;
    line-height: 32px;
    border-radius: 8px;
    font-size: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #363636;
}
.freetrial-dialog .plan-tabs .plan.active {
    background: #DBDEE3;
    font-weight: 600;
}

.freetrial-dialog .price-text {
    height: 62px;
    left: 14px;
    top: -9px;
    font-weight: 600;
    font-size: 44px;
    line-height: 140%;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    color: #5a5a5a;
    margin-top: 11px;
}
.freetrial-dialog .term {
    height: 27px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #727272;
}
.freetrial-dialog .price-break-down .price {
    border-top: 1px solid #DFE4EB;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    color: #727272;
    display: flex;
    width: 100%;
    padding: 8px;
    
}
.freetrial-dialog .price-break-down {
    margin-top: 28px;
    margin-bottom: 58px;
}
.freetrial-dialog .price-break-down .price .date {
    flex-grow: 1;
}
.freetrial-dialog .price-break-down .price.free-price {
    color: #CF5FA6;
    font-weight: 600;
    background: #F3F3F3;
}
.freetrial-dialog .price-break-down .price.free-price span{
    color:#CF5FA6
}
.freetrial-dialog .continue-btn {
    position: relative;
}
.freetrial-dialog .continue-btn .continue-loader {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.freetrial-dialog .continue-btn svg{
    display: block;
    margin: auto;
    cursor: pointer;
}
.freetrial-dialog .continue-btn .cancel-anytime{
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
    text-align: center;
    color: #5E5D5D;
    margin-top: 12px;
    margin-bottom: 24px;
}
.freetrialend-dialog-close svg {
    margin-left: auto;
    display: block;
}