.UndoIntroDialog-dialog {
  padding: 8px 7px 0px 7px;
}
.UndoIntroDialog-dialog-close svg {
  margin-left: auto;
  display: block;
  cursor: pointer;
}
.UndoIntroDialog-dialog .oops {
  text-align: center;
  font-size: 16px;
  line-height: normal;
  font-weight: 600;
  color: #43436a;
}
.UndoIntroDialog-dialog .devider {
  border: none;
  height: 2px;
  background-color: #f3a951;
  background: white;

  height: 6.5px;
  border-radius: 3.25px;
  margin: 20px auto;
}
.UndoIntroDialog-dialog .progress{
  background: #315a84;
  height: 6.5px;
  border-radius: 3.25px;
}
.UndoIntroDialog-dialog .text {
  color: black;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  margin: auto;
  margin-top: 15px;
  line-height: normal;
}
.UndoIntroDialog-dialog .text b {
  font-weight: 600;
}
.d-flex {
  display: flex;
}
.btn-wrapper {
  display: flex;
  margin: auto;
  margin-top: 10px;
}
.UndoIntroDialog-dialog .d-btn {
  width: 106px;
  text-align: center;
  margin: 15px 3px;
  display: block;
  background-color: #f3a951;
  color: #ffffff;
  border: none;
  font-size: 17px;
  height: 30px;
  line-height: 30px;
  font-weight: 400;
  border-radius: 8px;
  cursor: pointer;
  padding: 0px 5px;
}
.UndoIntroDialog-dialog .d-btn.m-later {
  color: #2d2d2d;
  background: transparent;
  font-weight: 300;
}
.MuiDialog-root {
  z-index: 5000 !important;
}
.d-btn.btn-no {
  background-color: #787878;
}
