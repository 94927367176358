.help-menu {
  background: #fbfbfb;
  padding: 12px 32px;
  width: 290px;
  border: 1px solid #cfcfcf;
  border-radius: 7px;
}
.help-menu .title {
  color: #000000;
  font-weight: 600;
  font-size: 26.666px;
  text-align: center;
}
.help-menu .sub-title {
  margin: 15px 0px;
  color: #0c0c0c;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
}
.help-menu .sub-title .bold {
  font-weight: 700;
}
.help-menu .sub-title.s2 {
  padding: 10px 0px;
}
.help-menu .pink-divider span {
  background: #d967be;
  height: 2px;
  width: 135px;
  margin: 5px auto;
  display: block;
}
.help-menu .link {
  color: #d967be;
  text-decoration: underline;
  font-size: 10.66px;
  font-weight: 300;
  text-align: center;
  padding: 15px 5px;
}
.help-menu img.back-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -63%);
}
.help-menu .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
