.footer-section {
  background-color: #e3e3e3;
  padding: 25px 0;
}

.footer-section .section-container {
  max-width: 1250px;
  margin: auto;
  display: flex;
}

.footer-section .section-container .img-part {
  width: 32%;
  text-align: center;
}

.footer-section .section-container .img-part .left-part-img {
  width: 187px;
  height: 187px;
}

.footer-section .section-container .img-part .img-part-text {
  color: #515151;
  text-decoration: none;
  font-size: 12px;
}

.footer-section .section-container .text-part {
  width: 68%;
  line-height: 27px;
}

.footer-section .section-container .text-part .text-wrap {
  display: flex;
}

.footer-section .section-container .text-part .text-wrap .item .item-title {
  color: #333333;
  text-decoration: none;
  font-weight: 600;
  font-size: 14px;
}

.footer-section .section-container .text-part .text-wrap .item .item-text {
  color: #333333;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .footer-section .section-container {
    display: block;
  }
  .footer-section .section-container .img-part {
    width: 100%;
    margin: auto;
  }
  .footer-section .section-container .text-part {
    width: 100%;
  }
  .footer-section .section-container .text-part .text-wrap {
    justify-content: center;
    padding: 25px 35px;
  }
}
.px-94 {
  padding: 0 94px;
}

@media only screen and (max-width: 768px) {
  .px-94 {
    padding: 0 35px;
  }
}
