.confirm-dialog {
  padding: 30px 0px 0px;
}
.confirm-dialog .text {
  color: #333333;
  font-size: 17.3333px;
}
.confirm-dialog .text b {
  font-weight: 600;
}
.d-flex {
  display: flex;
}
.btn-wrapper {
  display: flex;
  margin: auto;
  margin-top: 25px;
}
.d-btn {
  width: 106px;
  text-align: center;
  margin: 15px;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 13.33px;
  height: 36px;
  line-height: 36px;
  font-weight: 600;
  border-radius: 11px;
  cursor: pointer;
  padding: 0px 35px;
}
.d-btn.btn-no {
  background-color: #787878;
}
