.header {
  border-bottom: 1px solid #DFE4EB;
		// .header__navbar

  &__navbar {

    a {
      display: block;
      padding-top: 26px;
      padding-bottom: 30px;
    }
  }
}

.navigation {
  list-style: none;
  display: flex;
  align-items: center;
  column-gap: 40px;
  margin-bottom: 0;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    flex-wrap: nowrap;
  }

}

.login {

  // .login__link
  &__link {
    background: #CF5FA6;
    border-radius: 12px;
    padding: 7px 36px;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }
  }
}