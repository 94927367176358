body .welcome-wrapper {
  background-color: #f3f3f3;
  min-height: 100vh;
}
.welcome {
  margin: auto;
  border-radius: 10px;
  background-color: #ffffff;
  width: 90%;
  max-width: 724px;
  border: 1px solid #dddddd;
  padding: 40px;
  text-align: center;
  margin-top: 100px;
}
@media (max-width: 778px) {
  .welcome {
    margin-top: 60px;
    padding: 30px;
  }
}

.welcome .text {
  padding-top: 85px;
  color: #333333;
  font-weight: 600;
  font-size: 37.333px;
}
@media (max-width: 778px) {
  .welcome .text {
    font-size: 32px;
    padding-top: 60px;
  }
}
@media (max-width: 450px) {
  .welcome .text {
    font-size: 28px;
    padding-top: 40px;
  }
}

.welcome .btn-start {
  margin: auto;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 16px;
  width: 160px;
  height: 43px;
  line-height: 43px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 35px;
  cursor: pointer;
  margin-bottom: 85px;
}
@media (max-width: 778px) {
  .welcome .btn-start {
    margin-bottom: 60px;
  }
}
@media (max-width: 450px) {
  .welcome .btn-start {
    margin-bottom: 40px;
  }
}
