.account-sidemenu .header {
  height: 85px;
  background: #efefef;
  width: 100%;
  vertical-align: middle;
  position: relative;
}
.account-sidemenu .header .user-mail {
  line-height: 85px;
  color: #333333;
  font-weight: 600;
  padding: 0px 20px 0px 30px;
  display: flex;
  cursor: pointer;
}
.account-sidemenu .header .user-mail svg {
  margin: auto 5px;
}
.account-sidemenu .header img {
  vertical-align: middle;
}
.account-sidemenu .flex {
  position: relative;
}
.account-sidemenu .sidemenu {
  position: absolute;
  width: 280px;
  top: 0;
  bottom: 0;
  overflow: hidden;
  border-right: 1px solid #e7e7e7;
}
.account-sidemenu .sidemenu .item {
  display: block;
  color: #434343;
  font-size: 13.33px;
  font-weight: 600;
  text-align: center;
  line-height: 46px;
  border-bottom: 1px solid #e7e7e7;
  cursor: pointer;
}
.account-sidemenu .sidemenu .item.active {
  color: white;
  background: #4a5c6e;
}

.account-sidemenu .content {
  padding: 15px;
  overflow-x: hidden;
  min-height: 200px;
  min-height: CALC(100vh - 385px);
}
.account-sidemenu .pointer {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .account-sidemenu .user-mail,
  .account-sidemenu .user-icon,
  .account-sidemenu .sidemenu {
    display: none;
  }
  .account-sidemenu .content {
    padding-left: 15px;
  }

  .account-sidemenu .pointer {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%, -50%);
  }
}
