body .waiting-list-success-wrapper {
  background-color: #f3f3f3;
  min-height: 100vh;
}
.waiting-list-success-wrapper .welcome {
  margin: auto;
  border-radius: 10px;
  background-color: #ffffff;
  width: 90%;
  max-width: 724px;
  border: 1px solid #dddddd;
  padding: 40px;
  text-align: center;
  margin-top: 100px;
}
@media (max-width: 778px) {
  .waiting-list-success-wrapper .welcome {
    margin-top: 60px;
    padding: 30px;
  }
}

.waiting-list-success-wrapper .welcome .text {
  padding-top: 65px;
  color: #333333;
  font-weight: 700;
  font-size: 37.333px;
}
@media (max-width: 778px) {
  .waiting-list-success-wrapper .welcome .text {
    font-size: 32px;
    padding-top: 60px;
  }
}
@media (max-width: 450px) {
  .waiting-list-success-wrapper .welcome .text {
    font-size: 28px;
    padding-top: 40px;
  }
}

.waiting-list-success-wrapper .welcome .text {
  margin-bottom: 85px;
}
@media (max-width: 778px) {
  .waiting-list-success-wrapper .welcome .welcome .text {
    margin-bottom: 60px;
  }
}
@media (max-width: 450px) {
  .waiting-list-success-wrapper .welcome .welcome .text {
    margin-bottom: 40px;
  }
}
