.writing-pad-wrapper {
  padding: 15px;
  padding-top: 0px;
  padding-left: 15px;
  max-width: 1000px;
  width: 100%;
  margin: auto;
}
@media (max-width: 1550px) {
  .writing-pad-wrapper {
    padding-left: 105px;
  }
}
@media (max-width: 1366px) {
  .writing-pad-wrapper {
    padding-left: 155px;
  }
}
@media (max-width: 1280px) {
  .writing-pad-wrapper.brief-enable {
    padding-left: 290px;
  }
}
@media (max-width: 1100px) {
  .writing-pad-wrapper.brief-enable {
    width: 100%;
    margin-left: 0px;
    padding-left: 290px;
  }
}
@media (max-width: 888px) {
  .writing-pad-wrapper.brief-enable {
    padding-left: 290px;
  }
}

@media (max-width: 768px) {
  .writing-pad-wrapper {
    max-width: 100%;
  }
}
@media (max-width: 768px) {
  .writing-pad-wrapper {
    padding-left: 15px;
  }
  .writing-pad-wrapper.brief-enable {
    padding-left: 15px;
  }
}

.writing-pad-wrapper .states {
  font-size: 14px;
  font-weight: 300;
  color: black;
  position: fixed;
  top: 40px;
  left: 40px;
  z-index: 10;
}
@media (max-width: 768px) {
  .writing-pad-wrapper .states {
    display: none;
  }
}

.writing-pad-wrapper textarea:focus-visible,
.writing-pad-wrapper textarea:focus,
.writing-pad-wrapper textarea:hover,
.writing-pad-wrapper textarea:active {
  border: none;
  box-shadow: none !important;
  outline: 0px !important;
  -webkit-appearance: none !important;
}

.writing-pad-wrapper textarea.title {
  resize: none;
  border: none;
  width: 100%;
  color: #d967be;
  font-weight: 600;
  font-size: 32px;
  text-align: left;
  background-color:#ffffff;
}
.writing-pad-wrapper textarea.title::placeholder {
  color: #ddacd1;
  opacity: 1;
}
.writing-pad-wrapper textarea.title:-ms-input-placeholder {
  color: #ddacd1;
}
.writing-pad-wrapper textarea.title::-ms-input-placeholder {
  color: #ddacd1;
}

.writing-pad-wrapper textarea.brief {
  resize: none;
  border: none;
  width: 100%;
  color: #8c8c8c;
  font-weight: 400;
  font-size: 13.6667px;
  padding: 10px;
  line-height: 19px;
  text-align: left;
  max-height: calc(100vh - 420px);
  padding-right: 10px;
  margin-bottom: 20px;
  border-radius: 8px;
}
h4 {
  text-align: center;
}
.writing-pad-wrapper textarea.brief.mobile-brief {
  padding: 15px 20px 15px 20px;
  margin: auto;
  margin-bottom: 0px;
  height: 80px;
  max-width: 450px;
}

@media (max-width: 768px) {
  .writing-pad-wrapper textarea.brief.brief-desk {
    display: none;
  }
}

.writing-pad-wrapper textarea.brief::placeholder {
  color: #bbbbbb;
  opacity: 1;
}
.writing-pad-wrapper textarea.brief:-ms-input-placeholder {
  color: #bbbbbb;
}
.writing-pad-wrapper textarea.brief::-ms-input-placeholder {
  color: #bbbbbb;
}

.writing-pad-wrapper textarea.note {
  resize: none;
  border: none;
  width: 100%;
  overflow: hidden;
  font-weight: 400;
  color: black;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  padding: 0px;
  padding-right: 10px;
}
.writing-pad-wrapper textarea.note.loading {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.writing-pad-wrapper .writing-pad {
  overflow: scroll;
  /* -ms-overflow-style: none;   
  scrollbar-width: none; */
  max-height: calc(100vh - 170px);
  margin: 0px -15px;
  padding: 0px 15px;
}
/* .writing-pad-wrapper .writing-pad::-webkit-scrollbar {
  display: none;
} */

@media (max-width: 768px) {
  .writing-pad-wrapper textarea.note {
    max-height: calc(100vh - 440px);
    margin: 0px;
    padding: 0px;
  }
}

.writing-pad-wrapper textarea.note::placeholder {
  color: #bbbbbb;
  opacity: 1;
}
.writing-pad-wrapper textarea.note:-ms-input-placeholder {
  color: #bbbbbb;
}
.writing-pad-wrapper textarea.note::-ms-input-placeholder {
  color: #bbbbbb;
}

/* Selection Tooltip */
.writing-pad-wrapper .note-text-selection-tooltip {
  background-color: #676767;
  color: white;
  border-radius: 4px;
  display: none;
  font-weight: 300;
  font-size: 14px;
  max-width: 207px;
  z-index: 2000;
  align-items: center;
  position: absolute;
  animation: showTooltip 450ms ease-in-out both;
}
@keyframes showTooltip {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.writing-pad-wrapper .note-tooltip-item {
  padding: 5px 8px;
  cursor: pointer;
}
.writing-pad-wrapper .note-tooltip-item:hover {
  background-color: gray;
}
.writing-pad-wrapper .note-tooltip-item:first-child:hover {
  border-radius: 4px 0 0 4px;
}
.writing-pad-wrapper .note-tooltip-item:last-child:hover {
  border-radius: 0 4px 4px 0;
}
.writing-pad-wrapper .note-tooltip-divider {
  padding: 13.5px 0.5px;
  background-color: white;
}
.writing-pad-wrapper .bottom-arrow::before {
  content: "";
  position: absolute;
  bottom: -6px;
  border-top: 7px solid #676767;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.writing-pad-wrapper .bottom-arrow:hover::before {
  content: "";
  position: absolute;
  bottom: -6px;
  border-top: 7px solid gray;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
/* Selection Tooltip */

.writing-pad-wrapper ::-moz-selection {
  /* Code for Firefox */
  background: #fbedf7;
}
.writing-pad-wrapper ::selection {
  background: #fbedf7;
}

.writing-pad-wrapper .btn-write-desk {
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 13.33px;
  width: 75px;
  height: 25px;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  padding: 0px 10px;
  z-index: 1700;
  text-align: center;
  margin: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  text-align: center;
}
.writing-pad-wrapper .btn-write-desk span {
  margin: auto;
}

.writing-pad-wrapper .btn-humanize-desk {
  color: #d967be;
  border: 1px solid #d967be;
  font-size: 13.33px;
  gap: 3px;
  /* width: 75px; */
  height: 25px;
  font-weight: 600;
  border-radius: 6px;
  cursor: pointer;
  padding: 0px 10px 0px 6px;
  z-index: 1700;
  text-align: center;
  margin: 8px auto 8px 8px;
  display: flex;
  align-items: center;
  text-align: center;
}

.writing-pad-wrapper .btn-humanize-desk span {
  margin: auto;
}

.writing-pad-wrapper .btn-write {
  margin-right: auto;
  margin-left: auto;
  display: none;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 13.33px;
  width: 111px;
  height: 36px;
  line-height: 36px;
  font-weight: 600;
  border-radius: 11px;
  margin-top: 95px;
  cursor: pointer;
  margin-bottom: 70px;
  padding: 0px 25px;
  min-width: 35px;
  position: fixed;
  bottom: 45px;
  left: calc(50% - 28px);
  z-index: 1700;
  text-align: center;
}
.writing-pad-wrapper .btn-write.loading,
.writing-pad-wrapper .btn-write-desk.loading {
  background-color: rgba(217, 103, 190, 0.7);
  font-weight: 400;
}
.writing-pad-wrapper .btn-humanize-desk.loading {
  font-weight: 400;
  opacity: 0.7;
  border-color: rgba(217, 103, 190, 0.7);
}
.writing-pad-wrapper .btn-humanize{
  display: none;
}
@media (max-width: 768px) {
  .writing-pad-wrapper .btn-humanize {
    position: fixed;
    z-index: 1700;
    text-align: center;
    display: flex;
    bottom: 19.5px;
    left: calc(70% - 52.5px);
    width: 120px;
    height: 36px !important;
    line-height: 30px;
    margin: 0px !important;
    padding-left: 10px;
  }
  .writing-pad-wrapper .btn-write {
    display: block;
    margin: 0px;
    bottom: 19.5px;
    left: calc(30% - 52.5px);
  }
}
@media (max-width: 1000px) and (min-width: 768px) {
  .writing-pad-wrapper .btn-text-hidden-mobile {
    display: none;
  }
}
@media (max-width: 880px) {
  .writing-pad-wrapper .brief .text {
    display: none;
  }
  .writing-pad-wrapper .btn-write-desk {
    margin-left: 12px;
  }
}
.writing-pad-wrapper .btn-write.active {
  background-color: #a73b8d;
}
.writing-pad-wrapper .btn-undo,
.writing-pad-wrapper .btn-copy,
.writing-pad-wrapper .btn-desk,
.writing-pad-wrapper .btn-delete {
  position: relative;
  display: inline-block;
  color: #333;
  border: none;
  font-size: 15px;
  width: fit-content;
  font-weight: 300;
  border-radius: 11px;
  cursor: pointer;
  padding: 0px 5px;
  padding-top: 10px;
  min-width: 35px;
  line-height: 20px;
  padding-left: 20px !important;
}
.writing-pad-wrapper .action-btn.loading {
  opacity: 0.6;
}
.writing-pad-wrapper .stage {
  display: none;
}
@media (min-width: 1280px) {
  .writing-pad-wrapper .action-btn .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 0 0 0;
    overflow: hidden;
    margin-top: -15px;
  }
  .writing-pad-wrapper .action-btn .btn-text-hidden-mobile {
    opacity: 0;
    height: 0px;
    display: inline-block;
  }
}

.writing-pad-wrapper .btn-copy,
.writing-pad-wrapper .btn-desk,
.writing-pad-wrapper .btn-delete {
  margin-left: 8px;
}
.writing-pad-wrapper .btn-copy img,
.writing-pad-wrapper .btn-desk img,
.writing-pad-wrapper .btn-undo img,
.writing-pad-wrapper .btn-delete img {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 0px;
}
.writing-pad-wrapper .btn-delete {
  display: none;
}
.pink-line {
  height: 25px;
  background-color: #d967be;
  width: 1px;
  margin: 0px 10px;
  margin-top: 8px;
}

@media (max-width: 430px) {
  .writing-pad-wrapper .btn-undo,
  .writing-pad-wrapper .btn-copy,
  .writing-pad-wrapper .btn-desk,
  .writing-pad-wrapper .btn-delete {
    min-width: 20px;
  }
}

.writing-pad-wrapper .btn-header {
  display: flex;
  padding-bottom: 20px;
}
.writing-pad-wrapper .btn-header .btn-action.hover-zoom{
  transition: all 0s ease-in-out;
}
.writing-pad-wrapper .btn-header .btn-action.hover-zoom img {
  display: block;
}
.writing-pad-wrapper .btn-header .btn-action.hover-zoom img.hover-icon {
  display: none;
}
.writing-pad-wrapper .btn-header .btn-action.hover-zoom:hover img {
  display: none;
}
.writing-pad-wrapper .btn-header .btn-action.hover-zoom:hover img.hover-icon {
  display: block;
}
.writing-pad-wrapper .btn-header .btn-action.hover-zoom:hover {
  transform: scale(1);
  color: #d967be;
}
.writing-pad-wrapper .btn-header .btn-action.export-open{
  color: #d967be;
}
.writing-pad-wrapper .btn-header .btn-action.export-open img {
  display: none;
}
.writing-pad-wrapper .btn-header .btn-action.export-open img.hover-icon{
  display: block;
}

.writing-pad-wrapper .brief {
  line-height: 27px;
  font-size: 14px;
  color: #333333;
  display: flex;
  align-items: center;
  max-width: 220px;
  margin-left: auto;
}
@media (max-width: 768px) {
  .writing-pad-wrapper .btn-header {
    display: block;
    text-align: center;
    font-weight: 300;
    padding-right: 15px;
    margin-bottom: 30px;
    padding-bottom: 15px;
  }
  .writing-pad-wrapper .btn-header .brief,
  .writing-pad-wrapper .btn-desk,
  .writing-pad-wrapper .pink-line {
    display: none;
  }
  .writing-pad-wrapper .btn-delete {
    display: inline-block;
  }
}
.writing-pad-wrapper .brief span:first-child {
  margin-left: auto;
  margin-right: 7px;
}
.writing-pad-wrapper .brief span:last-child {
  padding-top: 4px;
}
.writing-pad-wrapper .brief img {
  cursor: pointer;
}
.writing-pad-wrapper .brief img:last-child {
  margin-left: 10px;
}
.writing-pad-wrapper .length-customizer-wrapper {
  position: fixed;
  left: 10px;
  top: 0;
  bottom: 0;
  width: 285px;
  background-color: #f4f4f4;
  z-index: 2;
  padding: 20px;
  padding-top: 150px;
  padding-left: 30px;
}
@media (max-height: 768px) {
  .writing-pad-wrapper .length-customizer-wrapper {
    padding-top: 100px;
  }
}
@media (max-height: 600px) {
  .writing-pad-wrapper .length-customizer-wrapper {
    padding-top: 80px;
  }
}
@media (max-height: 550px) {
  .writing-pad-wrapper .length-customizer-wrapper {
    padding-top: 70px;
  }
}

.writing-pad-wrapper .length-customizer-wrapper h4 {
  font-size: 16px;
  font-weight: 400;
  color: #db91c9;
}
.writing-pad-wrapper .length-customizer {
  margin-top: 40px;
}
@media (max-width: 768px) {
  .writing-pad-wrapper .length-customizer,
  .writing-pad-wrapper .length-customizer-wrapper {
    display: none;
  }
}
.writing-pad-wrapper .logo-space {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, -50px);
}
@media (max-height: 800px) {
  .writing-pad-wrapper .logo-space {
    bottom: 0px;
    transform: translate(-50%, -50px);
  }
}
@media (max-height: 700px) {
  .writing-pad-wrapper .logo-space {
    bottom: 0px;
    transform: translate(-50%, -15px);
  }
}
.writing-pad-wrapper .logo-space img {
  margin: auto;
  display: block;
}
.writing-pad-wrapper .logo-space span {
  font-size: 12px;
  margin: auto;
  display: block;
  text-align: center;
}
.writing-pad-wrapper .mobile-bottom {
  display: none;
}
@media (max-width: 768px) {
  .writing-pad-wrapper .mobile-bottom {
    display: block;
    position: fixed;
    height: 75px;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid #d9d9d9;
    z-index: 1600;
    background-color: white;
  }
  .writing-pad-wrapper .mobile-bottom .help-icon {
    margin-left: calc(16.66% - 18px);
    margin-top: 19.5px;
  }
  .writing-pad-wrapper .mobile-bottom .more-icon {
    position: absolute;
    right: calc(16.66% - 12px);
    top: calc(50% - 12px);
    cursor: pointer;
  }
}
.writing-pad-wrapper .icon-click {
  color: #d967be;
}
.writing-pad-wrapper .icon-img-click {
  filter: invert(62%) sepia(94%) saturate(1214%) hue-rotate(281deg)
    brightness(88%) contrast(91%);
}

.drawer-wrapper {
  background: #f4f4f4;
  padding: 10px 20px 10px 20px;
  margin-bottom: 75px;
}

.drawer-wrapper h4 {
  font-size: 16px;
  font-weight: 600;
  color: #db91c9;
}

.drawer-wrapper .length {
  display: flex;
  padding: 15px;
  padding-bottom: 0px;
}
.drawer-wrapper .length .customise-length-mobile {
  margin: auto;
  display: inline-block;
  padding-top: 0px;
  padding-bottom: 0px;
  max-width: 300px;
}
.drawer-wrapper .length .customise-length-mobile h4 {
  font-weight: 600;
}
.drawer-wrapper .close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}
.close-icon svg {
  margin: auto;
  cursor: pointer;
}
.mobile-title {
  display: inline;
  position: fixed;
  top: 45px;
  left: 50%;
  transform: translate(-50%, 0px);
  color: #9b9b9b;
}
@media (max-width: 768px) {
  .help-menu-popup {
    transform: translate(0px, -35px) !important;
  }
}

.writing-pad::-webkit-scrollbar-thumb {
  background: white;
}

.writing-pad:hover::-webkit-scrollbar-thumb {
  background: rgb(202, 202, 202);
}
.writing-pad-wrapper .mode{
  color: #d967be;
  font-weight: 600;
  font-size: 15px;
}

.writing-pad-wrapper .team-note-view-full-article{
  text-align: left;
  padding-bottom: 10px; 
}
.writing-pad-wrapper .team-note-view-full-article span{
  cursor: pointer;
  margin-right: auto;
  font-weight: 600;
  text-transform: capitalize;
  display: inline-flex;
}
.writing-pad-wrapper .team-note-view-full-article img {
  height: 20px;
}
.writing-pad-wrapper .devider {
  width: 7px;
  background: #d967be;
  border-radius: 10px;
  margin: 0px 10px;
}
