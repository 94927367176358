.accordion-item {

  background: #FFFFFF;
  box-shadow: 2px 2px 24px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  padding: 26px 28px;

  &.open {
    .accordion-item__body {
      display: block;
    }

    .accordion-item__caption {
      &::after {
        background-image: url('../../assestes/images/arrow-top.svg');
      }
    }
  }
  // .accordion-item__caption

  &__caption {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #363636;
    position: relative;
    cursor: pointer;

    &::after {
      content: ' ';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-image: url('../../assestes/images/arrow-bottom.svg');
      background-position: center;
      width: 36px;
      height: 36px;

    }
  }

  // .accordion-item__body

  &__body {
    display: none;
    margin-top: 16px;
    max-width: 860px;
  }
}