.upgrade-account {
  padding-top: 40px;
  background-color: #ffffff;
}
.upgrade-account .main-title {
  color: #333333;
  font-size: 26.6667px;
  font-weight: 600;
  text-align: center;
}
.upgrade-account .card-wrapper {
  padding-top: 55px;
  padding-bottom: 108px;
  background-color: #ffffff;
  display: flex;
  margin: auto;
  max-width: 1100px;
}
.UpgradeAccount .new-loader,
.UpgradeAccount .new-loader svg {
  position: absolute;
}
@media (max-width: 1100px) {
  .upgrade-account .card-wrapper {
    display: block;
    padding-bottom: 0px;
  }
}

.upgrade-account .card-wrapper .col {
  margin: auto;
}
@media (max-width: 1100px) {
  .upgrade-account .card-wrapper .col {
    padding-bottom: 75px;
  }
}
.upgrade-account .card-wrapper .card {
  margin: auto;
  width: 360px;
  padding: 40px 20px 20px 20px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.upgrade-account .card-wrapper .card2 {
  background-color: #fbfbfb;
}

.upgrade-account .card-wrapper .card .title {
  color: #434343;
  text-decoration: none;
  font-size: 18.6667px;
  font-weight: 600;
  text-align: center;
}
.upgrade-account .card-wrapper .card .price {
  color: #2a2a2a;
  text-align: center;
  font-weight: 600;
  font-size: 64px;
  line-height: 46px;
  margin-top: 35px;
}
.upgrade-account .card-wrapper .card .month {
  text-align: center;
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  margin-top: 35px;
  margin-bottom: 55px;
}
.upgrade-account .card-wrapper .card .lines-wrapper {
  display: flex;
  height: 37px;
}

.upgrade-account .card-wrapper .card img {
  margin-left: 8px;
  margin-right: 15px;
  width: 21px;
  height: 21px;
}
.upgrade-account .card-wrapper .card .lines {
  color: #333333;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  height: 37px;
}
.upgrade-account .card-wrapper .btn-get-me {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 220px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  border-radius: 11px;
  background-color: #d967be;
  text-align: center;
}
.upgrade-account .card-wrapper .btn-get-me.current {
  background-color: #16201873;
  cursor: unset;
}
@media (max-width: 500px) {
  .upgrade-account .card-wrapper .card {
    width: 320px;
  }
}
