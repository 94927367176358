.humanize-info-dialog{
    margin: 15px;
    margin-top: 0px;
}
.humanize-info-dialog .title {
    color: #363636;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
}
.humanize-info-dialog .sub-title {
    color: #727272;
    font-weight: 400;
    font-size: 14px;
    margin: auto;
    margin-top: 24px;
    text-align: center;
    max-width: 310px;
    
}
.humanize-info-dialog .okay-btn {
    width: 292px;
    text-align: center;
    margin: 44px auto 40px;
    display: block;
    background-color: #d967be;
    color: #ffffff;
    border: none;
    font-size: 14px;
    line-height: 44px;
    font-weight: 600;
    border-radius: 11px;
    cursor: pointer;
    display: flex;
    padding: 0px 40px;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}
@media (max-width: 768px) {
    .humanize-info-dialog .okay-btn {
        width:232px;
    }
}
.humanize-info-close svg {
    margin-left: auto;
    display: block;
    cursor: pointer;
}