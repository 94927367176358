.team-note-full-view {
  padding: 15px;
  padding-top: 0px;
  padding-left: 15px;
  max-width: 1000px;
  width: 100%;
  margin: auto;
  overflow: scroll;
  max-height: calc(100vh - 80px);
}

@media (max-width: 1550px) {
  .team-note-full-view {
    padding-left: 105px;
  }
}

@media (max-width: 1366px) {
  .team-note-full-view {
    padding-left: 155px;
  }
}
@media (max-width: 768px) {
  .team-note-full-view {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .team-note-full-view {
    padding-left: 15px;
  }
}

.team-note-full-view::-webkit-scrollbar-thumb {
  background: white;
}

.team-note-full-view:hover::-webkit-scrollbar-thumb {
  background: rgb(202, 202, 202);
}

.team-note-full-view .title {
  resize: none;
  border: none;
  width: 100%;
  color: #d967be;
  font-weight: 600;
  font-size: 32px;
  text-align: left;
}
.team-note-full-view .team-note-item .team-note-item-title {
  color: #434343;
  font-size: 14.66px;
  font-weight: 700;
  margin-top: 25px;
  margin-bottom: 17px;
}
.team-note-full-view .team-note-item .team-note-item-details{
  display: flex;
  white-space: pre-wrap;
}
.team-note-full-view .team-note-item .team-note-item-details .text {
  color: #434343;
  font-size: 14.66px;
  flex: 1;
  min-height: 40px;
  overflow-wrap: anywhere;
}
.team-note-full-view .team-note-item .team-note-item-details .devider {
  width: 7px;
  background: red;
  border-radius: 10px;
  margin: 0px 10px;
}
.team-note-full-view .team-note-item .team-note-item-details .assignee {
  color: #393939;
  font-weight: 300;
  width: 75px;
  position: relative;
 
}
.team-note-full-view .team-note-item .team-note-item-details .assignee .user-name{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0px, -50%);
  width: 90px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


body .Dashboard-wrapper {
  background-color: #ffffff;
}
.create-new-mobile {
  display: none;
}
@media (max-width: 768px) {
  .create-new-mobile {
    display: block;
    position: fixed;
    background: #4b4b4b;
    border-radius: 25px;
    width: 21px;
    height: 21px;
    top: 45px;
    left: 40px;
    cursor: pointer;
  }
  .create-new-mobile img {
    margin: -1px;
  }
}

.team-note-full-view .emptyView {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  text-align: center;
  opacity: 0.6;
}
.team-note-full-view .emptyView img{
  opacity: 0.7;
}
.team-note-full-view .emptyView .text1 {
  font-weight: 600;
  padding-bottom: 15px;
  font-size: 20px;
}
.team-note-full-view .emptyView .text2 {
  font-weight: 600;
  font-size: 16px;
}