.invite-teammember-dialog {
  padding: 0px 0px 0px;
}

.invite-teammember-dialog.close svg {
  margin-left: auto;
  display: block;
  cursor: pointer;
}
.invite-teammember-dialog .d-flex {
  display: flex;
}
.invite-teammember-dialog .btn-wrapper {
  display: flex;
  margin: auto;
  margin-top: 15px;
}
.invite-teammember-dialog .d-btn {
  width: 126px;
  text-align: center;
  margin: 15px;
  margin-top: 10px;
  display: block;
  background-color: #d967be;
  color: #ffffff;
  border: none;
  font-size: 13.33px;
  height: 36px;
  line-height: 36px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
}
.invite-teammember-dialog .d-btn.disabled{
  background-color: #787878 !important;
}
.invite-teammember-dialog .d-btn.btn-no {
  background-color: #787878;
}
.invite-teammember-dialog .title{
  text-align: center;
  color: #363636;
  font-weight: 600;
  font-size: 19px;
}
.invite-teammember-dialog .title img{
  margin-left: 10px;
}
.invite-teammember-dialog .email-input {
  margin: 27px 0px 5px 0px;
  width: 100%;
  color: #000000;
  font-size: 16px;
  vertical-align: middle;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 10px 10px 10px 15px;
  filter: drop-shadow(4.0px 4.898587196589413E-16px 9px #C5C5C5);
  cursor: text;
}
.invite-teammember-dialog .email-input .tag{
  color: #434343;
  font-size: 13.333px;
  line-height: 22px;
  background-color: #eaeaea;
  padding-left: 5px;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
  margin-right: 7px;
}
.invite-teammember-dialog .email-input .tag .remove-tag{
  margin-left: 5px;
  cursor: pointer;
  padding: 7px;
  background: red;
}
.invite-teammember-dialog .email-input .tag .remove-tag svg{
  margin: -6px;

}
.invite-teammember-dialog .email-input input{
  width: 100%;
  border: none;
  font-size: 14px;
}

.error-top {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #d95a4f;
  color: #FFFFFF;
  font-size: 14px;
  vertical-align:middle;
  text-align: center;
  min-width: 525px;
  padding: 3px 10px 5px 10px;
  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
}