* {
  font-family: 'Open Sans';
}

h1 {
  font-weight: 400;
  font-size: 48px;
  line-height: 62px;
  letter-spacing: 0.01em;
  color: #363636;
  margin: 0;
}

h2 {
  font-weight: 400;
  font-size: 32px;
  line-height: 45px;
  letter-spacing: 0.01em;
  color: #363636;
  margin: 0;
}

h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.04em;
  margin: 0;
  color: #363636;
}

h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  margin: 0;
  color: #727272;
}

a {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #9A9A9A;

  &:hover {
    color: #363636;
  }
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #727272;
}

input {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #AEAEAE;
  padding: 10px 12px;
}